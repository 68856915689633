import {Badge, Box, Card} from '@mantine/core'
const GridView = ({items, updatTable, availableTable}) =>
{
  return (
    <div className='flex flex-row gap-4 flex-wrap justify-start items-stretch '>
      { items ? items.map((item, i) => (

        <Card shadow='md' radius="lg" withBorder p="md" className="w-60 sm:w-auto">
          <Box ><span className='font-semibold'>{ item?.name }</span></Box>
          <Box className='flex flex-row gap-2'>
            <Box>{ item?.status ? <Badge autoContrast radius="md" variant='outline' onClick={ () => updatTable(item._id, false) } color='blue'>visible</Badge> : <Badge autoContrast radius="md" variant='outline' color='red' onClick={ () => updatTable(item._id, true) }>oculto</Badge> }</Box>

            { item?.status ?
              <span>{ item?.serviceStatus ? <Badge autoContrast radius="md" variant='filled' color='red' onClick={ () => availableTable(item._id, false) }>Ocupada</Badge> : <Badge autoContrast radius="md" variant='filled' onClick={ () => availableTable(item._id, true) } color='green'>Disponible</Badge> }</span>

              :
              <span><Badge autoContrast radius="md" variant='filled' color='gray'>No asignable</Badge></span>
            }
          </Box>
        </Card>

      )) : null }

    </div>
  )
}

export default GridView