export const get_transactions = async (data) => {
  try {
    const token =  await localStorage.getItem('token')
    const url = `${process.env.REACT_APP_API_URL}/api/v1/transactions?date=${data.date}`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      // body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const get_transaction = async (id) => {
  try {

    const token =  await localStorage.getItem('token')
    const url = `${process.env.REACT_APP_API_URL}/api/v1/transactions/${id}`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const post_transaction = async (data) => {
  const token =  localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/transactions`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const delete_transaction = async (id) => {
  try {
    const token = await localStorage.getItem('token')
    const url = `${process.env.REACT_APP_API_URL}/api/v1/transactions/${id}`;
    const params = {
      method: 'DELETE',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}
