import { Card, Title, Table } from '@mantine/core'
import { RowByProducts } from "./RowByProducts"

export const SalesByProducts = ({ products }) =>
{
  return (
    <Card className="w-full" p="md" withBorder radius="md">
      <Title order={ 4 } className='uppercase'>
        Productos vendidos
      </Title>
      <Table highlightOnHover>
        <Table.Tbody>
          { products
            ? products.map((product) => (
              <RowByProducts key={product._id} { ...product } />
            ))
            : null }
        </Table.Tbody>
      </Table>
    </Card>
  )
}
