import { Card, ScrollArea, Table, Skeleton, Box } from '@mantine/core'
import ProductRow from './ProductRow'
export const ProductsTable = ({ items, editProduct, updateProduct, updateRecommended, deleteProduct }) =>
{
  return (
    <Card withBorder shadow='md' my="md" radius="xl">

      <ScrollArea className='w-full'>
        <Table highlightOnHover className='w-full overflow-x-auto'>
          <Table.Thead>
            <Table.Tr>
              <Table.Th className="w-64">Nombre</Table.Th>
              <Table.Th className="w-24">Precio</Table.Th>
              <Table.Th className="w-24">Cnt</Table.Th>
              <Table.Th className="w-24">Estado</Table.Th>
              <Table.Th className="w-24">Cat</Table.Th>
              <Table.Th className="w-24">Recomendado</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            { items
              ? items.map((item) => (
                <ProductRow
                  key={ item._id }
                  item={ item }
                  editProduct={ editProduct }
                  updateProduct={ updateProduct }
                  updateRecommended={ updateRecommended }
                  deleteProduct={ deleteProduct }
                />
              ))
              : [1,2,3,4,5,6,7,8,9,10].map(index =>(
                <Table.Tr key={index} className="w-full">
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                </Table.Tr>
              )) }
          </Table.Tbody>
        </Table>
      </ScrollArea>

    </Card>
  )
}
