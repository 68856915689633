const token =  localStorage.getItem('token')
export const get_sliders = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/uploads`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const get_slider = async (id) => {
  try {

    console.log(id)
    const url = `${process.env.REACT_APP_API_URL}/api/v1/uploads/${id}`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
      }
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const post_slider = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/uploads`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
      },
      body: data
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const suspend_slider = async (id) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/uploads/suspend/${id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const delete_slider = async (id) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/uploads/delete/${id}`;
    const params = {
      method: 'DELETE',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const activate_slider = async (id) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/uploads/active/${id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}
