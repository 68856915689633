export const _getExpiredDate = async () => {
  try {
    const url = `${process.env.REACT_APP_SSO_API_URL}/api/v1/applications/expired/abc`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json'
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}
