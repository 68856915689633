import React, { useEffect, useState } from 'react'
import { Flex, Text, TextInput, NumberInput, Button, Box, ActionIcon } from '@mantine/core'
import { NumericFormat } from 'react-number-format';
import { PiTrashDuotone } from 'react-icons/pi'
import { post_extra_to_product, delete_extras_to_product, get_extras_by_product } from '../../utils/apis/extras'
const ExtrasSection = ({ productId }) =>
{
  const [ extras, setExtras ] = useState()
  const [ extra, setExtra ] = useState({
    name: '',
    price: 0
  })
  const get_extras = async () =>{
    const data = await get_extras_by_product(productId)
    setExtras(data.results)
  }
  const addExtra = async () =>
  {
    const data = {
      name: extra.name,
      price: extra.price
    }
    await post_extra_to_product(productId, data)
    get_extras()
  }
  const deleteExtra = async (id) =>{
    await delete_extras_to_product(id)
    get_extras()
  }
  useEffect(() =>{
    get_extras()
  },[productId])
  return (
    <Flex direction={ { base: 'column', md: 'row' } } gap="md" w={ '100%' } >
      <Box w={ '100%' }>
        <Text>Extras</Text>
        <div className='w-12/12'>
          <Flex gap="md" justify="flex-end" align="center" w={ '100%' }>
            <TextInput value={ extra.name } placeholder="Nombre del extra" onChange={ (e) => setExtra({ ...extra, name: e.target.value }) } />
            <NumberInput value={ extra.price } placeholder="Precio" onChange={ (e) => setExtra({ ...extra, price: e }) } />
            <Button variant="light" color="blue" onClick={ addExtra }>Agregar</Button>
          </Flex>
        </div>
        { extras && extras.map((extra) => (
          <Flex key={ extra._id } justify="space-between" align="center" my="sm" gap="md" w={ '100%' }>
            <Flex justify="space-between" align="center" my="sm" gap="md" w={ '100%' }>
              <Text>{ extra.name }</Text>
              <NumericFormat
                value={ extra.price }
                displayType={ 'text' }
                thousandSeparator={ true }
                prefix={ '$ ' }
                decimalScale={ 2 }
                fixedDecimalScale={ true }
              />
            </Flex>
            <ActionIcon color="red" variant="light" radius="xl" onClick={()=>deleteExtra(extra._id)}>

              <PiTrashDuotone />
            </ActionIcon>
          </Flex>
        )) }
      </Box>

    </Flex>
  )
}

export default ExtrasSection