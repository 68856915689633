import React, { useState, useRef, useContext } from 'react'
import { useReactToPrint } from 'react-to-print';
import { Card, ActionIcon, Badge, Flex, Box, Text, useMantineColorScheme, Tooltip, Divider, Avatar, Loader } from '@mantine/core';
import { PiPrinterDuotone, PiArrowDown, PiArrowUp, PiBagDuotone, PiTagFill, PiChairDuotone, PiMotorcycleDuotone, PiTrashDuotone } from 'react-icons/pi'
import dayjs from 'dayjs';
import Ticket from '../../Tickets/Ticket';
import AuthContext from '../../../contexts/Auth/authContext';

const ComponentToPrint = React.forwardRef((props, ref) =>
{

  return (
    <div className="hidden print:block" ref={ ref }>
      <Ticket order={ props.order } />

    </div>
  );
});

const DailyRow = ({ order, onConfirm, report = true }) =>
{
  const [ show, setShow ] = useState(false);
  const [ isLoadingTicket, setIsLoadingTicket ] = useState(false);
  const { user } = useContext(AuthContext);
  const printOrder = async (id) =>
  {

    handlePrint()
  };

  const { colorScheme } = useMantineColorScheme();



  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () =>
    {
      const current = componentRef.current;
      if (current) {
        current.style.marginBottom = '20mm'; // Ajuste dinámico
      }
      return current;
    },
    onAfterPrint: () =>
    {
      setIsLoadingTicket(false);
    }
  });
  return (
    <Card shadow='md' px="md" py="sm" my="md" withBorder radius="lg">
      <ComponentToPrint key={ order._id } order={ order } ref={ componentRef } />
      <Box className='flex flex-row justify-between items-center'>

        <Flex direction={ { base: 'column', md: 'row' } } gap="sm" align={ { base: 'flex-start', md: "center" } }>
        {!report && user.role.includes("SUPER_ADMIN") && <Tooltip label="Eliminar" aria-label='A tooltip' >
            <ActionIcon onClick={ ()=>{onConfirm(order)}} color={ 'red' } size="md">
              <PiTrashDuotone size='1.2em' />
            </ActionIcon>
          </Tooltip> }
          <Badge color={ order.methodPayment === 'efectivo' ? 'green' : 'blue' } size="lg">{ order.methodPayment }</Badge>
          <Text fz="sm">{ dayjs(order.dateTransaction).format('DD MMM YYYY, HH:mm') }</Text>
        </Flex>
        <Flex direction={ { base: 'column', md: 'row' } } gap="md" align={ { base: 'flex-start', md: "center" } }>
          <Box className='flex flex-row gap-4 justify-center items-center'>

            <Tooltip label="Imprimir ticket" aria-label='A tooltip' >{ !isLoadingTicket ?
              <ActionIcon onClick={ () => printOrder(order._id) } color={ colorScheme === 'dark' ? 'white' : 'dark' } variant="subtle" size="lg">
                <PiPrinterDuotone size='1.2em' />
              </ActionIcon>
              :
              <Box mx='2' my='2'>
                <Loader size='sm' />
              </Box>

            }</Tooltip>
          </Box>
          <Tooltip label={ show ? 'Cerrar' : 'Abrir' }>
            <ActionIcon onClick={ () => setShow(!show) } color={ colorScheme === 'dark' ? 'white' : 'dark' } variant="subtle" size="lg">
              { show ? <PiArrowUp size='1.2em' /> : <PiArrowDown size='1.2em' /> }
            </ActionIcon>

          </Tooltip>
        </Flex>
      </Box>
      {
        show && <>    <Box className='grid grid-cols-2 place-items-between gap-2 w-full mt-2'>
          <Flex gap="md" align="center" key={ order._id }>
            <Flex gap="md" align="center">
              <Flex gap="sm" align="center">
                { order.orderType === 'local' && <PiChairDuotone size="24" /> }
                { order.orderType === 'delivery' && <PiMotorcycleDuotone size="24" /> }
                { order.orderType === 'pickup' && <PiBagDuotone size="24" /> }
                { order.orderType === 'local' && <Text fz="sm">{ order.orderInfo.table }</Text> }
                { order.orderType === 'delivery' && <Text fz="sm">Para entregar</Text> }
                { order.orderType === 'pickup' && <Text fz="sm">Para llevar</Text> }
              </Flex>
            </Flex>
            <Flex align="center" gap="sm">
              <Avatar name={ order.orderInfo?.name } size='sm' color='dark' />
              <Text fz="sm">{ order.orderInfo?.name }</Text>
            </Flex>


          </Flex>

        </Box>


          <Divider my="sm" />
          <Flex direction={ { base: "column", lg: "row" } } justify={ { base: "flex-start", lg: "space-between" } } gap="sm">
            <Flex gap="sm" align="center" justify={ { base: "flex-start", lg: "space-between" } }>
              <Text fz="xs">Cajero:</Text>
              <Flex className='flex flex-row gap-1 items-center justify-end'>
                <Avatar name={ order.user.name } size='sm' color='blue' />
                <Text fz="sm">{ order.user.name }</Text>
              </Flex>
            </Flex>

            <Flex gap="sm" align="center" justify={ { base: "flex-start", lg: "space-between" } }>
              <Text fz="xs">Mesero:</Text>
              <Flex className='flex flex-row gap-1 items-center justify-end'>
                <Avatar name={ order.waiter?.name } size='sm' color="indigo" />
                <Text fz="sm">{ order.waiter?.name }</Text>
              </Flex>
            </Flex>
          </Flex>
          <Divider className='my-2' />
          <Flex align={ { base: "flex-start", md: "center" } } px="xs" gap="sm" mt="md">
            <PiTagFill size="1.2rem" />
            <Text fz="sm">{ order.orderProducts.length } productos</Text>
          </Flex>
          <Box>
            { order.orderProducts ? order.orderProducts.map(item => (
              <Box key={item._id} className='my-2 border-b last:border-b-0 p-2 flex flex-row justify-between'>
                <Box className='flex flex-row gap-2 items-baseline'>
                  <Text fz="xs"> { item.quantity } x</Text>
                  <Text fz="md"> { item._id.name }</Text>
                </Box>
                <Text fw="bold">$ { (item.price * item.quantity).toFixed(2) }</Text>
              </Box>
            )) : null }
          </Box>
        </> }
      <Divider variant="dashed" my="sm" />
      <Flex direction="column" gap="xs">
        { show && <Flex justify="flex-end" align="flex-end" gap="sm">
          <Text fz="xs">Subtotal</Text>
          <Text className='text-right' fz="sm" fw="bold">${ order?.subtotal.toFixed(2) }</Text>
        </Flex> }
        { show && <Flex justify="flex-end" align="flex-end" gap="sm">
          <Text fz="xs">Desc.</Text>
          <Text className='text-right' fz="sm" fw="bold">{ order?.discount } %</Text>
        </Flex> }
        { show && <Flex justify="flex-end" align="flex-end" gap="sm">
          <Text fz="xs">IVA</Text>
          <Text className='text-right' fz="sm" fw="bold">${ order?.tax.toFixed(2) }</Text>
        </Flex> }
        <Flex justify="flex-end" align="flex-end" gap="sm">
          <Text fz="xs">Total</Text>
          <Text className='text-right' fz="sm" fw="bold">${ order?.total.toFixed(2) }</Text>
        </Flex>
      </Flex>
    </Card>
  )
}

export default DailyRow