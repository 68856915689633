import { Card, Box, Text, TextInput, Title } from '@mantine/core';
import { PiMapPin, PiPhone, PiClockAfternoon } from 'react-icons/pi';

const Branches = ({ localSettings, handleInputChange }) =>
{
  return (
    <Card shadow='md' px="md" py="sm" my="md" withBorder radius="lg">
      <Box>
        <Title order={ 2 }>Sucursal</Title>
      </Box>

      { localSettings.branches && localSettings.branches.map((item, index) => (
        <Box key={ index } className='flex flex-col gap-2'>
          <Text>{ item.name }</Text>
          <Box className='flex flex-row gap-1 items-center w-full'>
            <PiMapPin />
            <TextInput
            className='w-full'
              placeholder='dirección'
              value={ item.address }
              onChange={ (e) => handleInputChange(index,'address', e.target.value) }
            />
          </Box>
          <Box className='flex flex-row gap-1 items-center w-full'>
            <PiPhone />
            <TextInput
            className='w-full'
              placeholder='Teléfono 1'
              value={ item.phone1 }
              onChange={ (e) => handleInputChange(index,'phone1',e.target.value) }
            />
          </Box>
          <Box className='flex flex-row gap-1 items-center w-full'>
            <PiPhone />
            <TextInput
            className='w-full'
              placeholder='Teléfono 2'
              value={ item.phone2 }
              onChange={ (e) => handleInputChange(index,'phone2',e.target.value) }
            />
          </Box>
          <Box className='flex flex-row gap-1 items-center w-full'>
            <PiClockAfternoon />
            <TextInput
            className='w-full'
              placeholder='Horario 1'
              value={ item.schedule1 }
              onChange={ (e) => handleInputChange(index,'schedule1', e.target.value) }
            />
          </Box>
          <Box className='flex flex-row gap-1 items-center w-full'>
            <PiClockAfternoon />
            <TextInput
            className='w-full'
              placeholder='Horario 2'
              value={ item.schedule2 }
              onChange={ (e) => handleInputChange(index,'schedule2', e.target.value) }
            />
          </Box>
        </Box>
      )) }

    </Card>
  )
}

export default Branches