import { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import {postLogin, generateRecoveryToken} from '../../utils/apis/auth'

import {
    SUCCESSFUL_SIGNIN,
    ERROR_SIGNIN,
    GET_USER_INFO,
    SUCCESSFUL_LOGIN,
    ERROR_LOGIN,
    LOGOUT,
    BLOCK,
    GENERATE_RECOVERY
} from '../types';
import { get_user } from '../../utils/apis/users';

const AuthState = (props) => {
    
    //STATE INICIAL
    const initialState = {
        user: JSON.parse(localStorage.getItem('user')),
        message: null,
        token: localStorage.getItem('token'),
        autenticado: null
    };

    // Dispatch para ejecutar las acciones

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    const sign_in = async (datos) => {
        // try {
        //     const res = await postSignIn(datos)
        
        //     if (res.msg === 'done') {
                
        //         dispatch({
        //             type: SUCCESSFUL_SIGNIN,
        //             payload: res
        //         })
        //         usuarioAutenticado(res.user)
        //     } else {
        //         const alerta = {
        //             msg: res.msg,
        //             categoria: 'danger'
        //         }

        //         dispatch({
        //             type: ERROR_SIGNIN,
        //             payload: alerta


        //         })
        //     }
        // } catch (errors) {
        //         console.log('ERROR', errors)
        //     const alerta = {
        //         msg: 'ERROR',
        //         categoria: 'alerta-error'
        //     }

        //     dispatch({
        //         type: ERROR_SIGNIN,
        //         payload: alerta


        //     })
        // }


    }

    const login = async (datos) => {
        try {
            const res = await postLogin(datos)
            console.log('RES ',res)
            if (res.results) {
                
                dispatch({
                    type: SUCCESSFUL_LOGIN,
                    payload: res.results
                })

                usuarioAutenticado(res.results.user)

              
            
            } else {
                const alerta = {
                    msg: res.msg,
                    categoria: 'error'
                }
                console.log('ALETA ' , alerta)

                dispatch({
                    type: ERROR_LOGIN,
                    payload: alerta


                })
            }

        } catch (error) {
            console.log(error)
            const alerta = {
                msg: 'Algo salio mal, intenta mas tarde',
                categoria: 'error'
            }

            dispatch({
                type: ERROR_LOGIN,
                payload: alerta


            })
        }


    }

    // Obtener datos de usuario

    const usuarioAutenticado = async (userData) => {


        try {
            if(userData){
               
                dispatch({
                    type: GET_USER_INFO,
                    payload: userData
                })
            }else{
                const res = await get_user()
                dispatch({
                    type: GET_USER_INFO,
                    payload: res.results.user
                })
            }

            

        } catch (error) {
            dispatch({
                type: ERROR_LOGIN
            })
        }
    }

    const block = () => {
        dispatch({
            type: BLOCK
        })
    }


    const logout = () => {
        dispatch({
            type: LOGOUT
        })
    }

    const generateRecovery = async (datos) => {
        try {
            const res = await generateRecoveryToken(datos)
            if (res.msg ==='done') {
                
                dispatch({
                    type: GENERATE_RECOVERY,
                    payload: res.results
                })
                return true
            } else {
                const alerta = {
                    msg: res.msg,
                    categoria: 'error'
                }

                dispatch({
                    type: ERROR_LOGIN,
                    payload: alerta


                })
            }
        } catch (error) {
            console.log(error)
            const alerta = {
                msg: 'Algo salio mal, intenta mas tarde',
                categoria: 'error'
            }

            dispatch({
                type: ERROR_LOGIN,
                payload: alerta


            })
        }


    }


    return (
        <AuthContext.Provider value={{
            user: state.user,
            token: state.token,
            refreshToken:state.refreshToken,
            autenticado: state.autenticado,
            message: state.message,
            sign_in,
            usuarioAutenticado,
            login,
            logout,
            block,
            generateRecovery

        }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;