const token =  localStorage.getItem('token')
export const get_tables = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/table`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

// export const get_category = async (id) => {
//   try {

//     console.log(id)
//     const url = `${process.env.REACT_APP_API_URL}/api/v1/category/${id}`;
//     const params = {
//       method: 'GET',
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         "Content-Type": "application/json",
//         'Accept': 'application/json',
//       }
//     };

//     const response = await fetch(url, params);
//     const result = await response.json()
//     return result

//   } catch (error) {
//     console.log(error)
//     return null
//   }
// }

export const post_table = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/table`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const update_table = async (id,data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/table/${id}`;
    const params = {
      method: 'PUT',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const update_position_table = async (id,data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/table/position/${id}`;
    const params = {
      method: 'PUT',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}
