import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { get_tables, post_table, update_table } from '../../../utils/apis/tables';
import ChangePosition from '../../../components/Modal/Tables/ChangePosition';
import GridView from '../../../components/Tables/GridView';
import MapView from '../../../components/Tables/MapView';
import { TextInput, Title, Button, Flex, ActionIcon, Skeleton, Box } from '@mantine/core';
import { PiPlus, PiMapTrifoldDuotone, PiGridNineDuotone } from 'react-icons/pi';

const Tables = () =>
{
  const [ view, setView ] = useState('map');
  const [ loading, setLoading ] = useState(true);
  const [ items, setItems ] = useState();
  const [ show, setShow ] = useState(false);
  const [ itemSelected, setItemSelected ] = useState(null)

  useEffect(() =>
  {
    (async () =>
    {
      await listTables()
    })()
  }, [])

  useEffect(() =>
  {
    listTables()
  }, [ loading ])

  const listTables = async () =>
  {
    const res = await get_tables();
    setItems(res.results)
    setLoading(false)
  }
  const resteFormikValue = async () =>
  {
    formik.resetForm()
  }
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {

      const table = {
        name: data.name,
        status: true,
        serviceStatus: true,
      }
      await post_table(table)

      resteFormikValue()
      listTables()
    }
  })

  const updatTable = async (id, newStatus) =>
  {
    setLoading(true)
    await update_table(id, { status: newStatus })
    listTables()
  }

  const availableTable = async (id, newStatus) =>
  {
    setLoading(true)
    await update_table(id, { serviceStatus: newStatus })
    listTables()
  }

  const openChangePosition = (item) =>
  {
    setShow(true)
    setItemSelected(item)
  }
  const closeChangePosition = () =>
  {
    setShow(false)
    setItemSelected(null)
    setLoading(true)
  }



  return (
    <>
      <Flex direction={ { base: "column", md: "row" } } justify={ { base: "flex-start", md: "space-between" } } align={ { base: 'flex-start', md: 'center' } } gap="sm">
        <Title order={ 1 }>Mesas</Title>
        <Flex
          direction={ { base: "column", md: "row" } }
          justify={ { base: "flex-start", md: "flex-end" } }
          align={ { base: 'flex-start', md: 'flex-end' } }
          gap="sm"
          className='w-full md:w-4/12'>


          <TextInput
            variant='filled'
            size='md'
            label="Nombre de la mesa" placeholder='Nombre de la mesa' value={ formik.values.name } onChange={ (e) => formik.setFieldValue('name', e.target.value) } />
          <Button
            size='md'
            radius="md"
            leftSection={ <PiPlus className="text-xl" /> }
            color='blue'
            onClick={ () => formik.handleSubmit() }>Nueva</Button>

        </Flex>
      </Flex>
      <Flex className='flex flex-row items-end justify-end gap-2 my-4'>
        <ActionIcon size="lg" variant={ view === 'map' ? "filled" : "light" } color='gray' onClick={ () => setView('map') }>
          <PiMapTrifoldDuotone className='text-xl' />
        </ActionIcon>
        <ActionIcon size="lg" variant={ view === 'grid' ? "filled" : "light" } color='gray' onClick={ () => setView('grid') }>
          <PiGridNineDuotone className='text-xl' />
        </ActionIcon>
      </Flex>
      { items ? <Box className='my-2 overflow-y-auto overflow-x-auto h-screen'>
        { view === 'grid' && <GridView items={ items } updatTable={ updatTable } availableTable={ availableTable } /> }
        { view === 'map' && <MapView items={ items } updatTable={ updatTable } availableTable={ availableTable } openChangePosition={ openChangePosition } /> }
      </Box> :
        <Flex direction="row" gap="md" align="center">
          <Skeleton radius="lg" height={ 100 } width={ 200 } />
          <Skeleton radius="lg" height={ 100 } width={ 200 } />
          <Skeleton radius="lg" height={ 100 } width={ 200 } />
          <Skeleton radius="lg" height={ 100 } width={ 200 } />
          <Skeleton radius="lg" height={ 100 } width={ 200 } />
        </Flex>
      }
      <ChangePosition show={ show } setShow={ openChangePosition } item={ itemSelected } close={ closeChangePosition } />
    </>
  )
}

export default Tables


function initialValues ()
{
  return {
    name: '',
  }
}

function validationSchema ()
{
  return {
    name: Yup.string().required(true),
  }
}