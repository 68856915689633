import React from 'react'
import { Text, Box, Divider } from '@mantine/core'
import Logo from '../../assets/logo.png'
import Row from './OrderSummary/Row'

import dayjs from 'dayjs'
export const TicketHeader = ({ order }) =>
{
  return (
    <Box>
      <img src={ Logo } alt="logo" className='mx-auto w-24' />
      <Box className='grid grid-cols-1 justify-start items-start md:items-start gap-1' w='100%'>
        <Box className='flex flex-row justify-between'>
          <Box className='flex flex-row items-start gap-1'>
            <Text fz="xs" className=' ticketFont'>Atendido por:</Text>
            <Text fz="xs" className=' ticketFont'>{ order.waiter?.name }</Text>
          </Box>
        </Box>
        <Divider my={ 2 }  className='text-gray-300'/>
        <Box className='grid grid-cols-1 md:flex flex-row'>
          <Row colName='Pedido' colInfo={ `# ${ order._id }` } />
          <Row colName='Fecha: ' colInfo={ dayjs(order.orderDetail?.date).format('DD/MM/YYYY HH:mm a') } />
          <Row colName='Mesa' colInfo={ order.orderInfo.table || 'N/A' } />
          <Row colName='Dirección:' colInfo={ order?.orderInfo?.address || 'N/A' } />
        </Box>

      </Box>
    </Box>
  )
}
