import { Divider, Text, Box } from '@mantine/core'
import React from 'react'
import TicketResumenRow from './TicketResumenRow'

import { TicketHeader } from './TicketHeader'
import TicketFooter from './TicketFooter'

const Ticket = ({ order }) =>
{
  return (
    <div className='flex flex-col items-center'>
      <Box className='Ticket w-full hidden print:block ticketFont' w='100%' h={ '120%' }>
        <TicketHeader order={ order } />
        <Divider my={ 2 } />
        <Box className='w-full'>
          {
            order.orderProducts ? order.orderProducts.map((item, i) => (
              <TicketResumenRow key={ i } product={ item } />
            ))
              : null
          }




        </Box>
        <Divider py={ 2 } />
        <Box className='w-full flex flex-row justify-between items-center gap-2 my-2'>
          <Box className='w-full'>
            <Box className='flex flex-row justify-between'>
              <Text fz="xs" className='ticketFont'>Subtotal</Text>
              <Text fz="xs" className='ticketFont text-right'>$ { order.subtotal ? (order.subtotal.toFixed(2) - order.tax.toFixed(2)) : '0.00' }</Text>
            </Box>

            <Box className='flex flex-row justify-between'>
              <Text fz="xs" className='ticketFont'>IVA</Text>
              <Text fz="xs" className='ticketFont text-right'>$ { order.tax ? order.tax.toFixed(2) : '0.00' }</Text>
            </Box>
            <Box className='flex flex-row justify-between'>
              <Text fz="xs" className='ticketFont'>Desc</Text>
              <Text fz="xs" className='ticketFont text-right'>$ -{ order.discount ? (order.subtotal.toFixed(2) * (order.discount / 100)).toFixed(2) : '0.00' }</Text>
            </Box>
            <Box className='flex flex-row justify-between'>
              <Text fz="xs" className='ticketFont'>Total</Text>
              <Text fz="xs" className='ticketFont text-right'>$ { order.total ? order.total.toFixed(2) : '0.00' }</Text>
            </Box>
            <Divider my={ 2 } className='text-gray-300'/>
            <Box className='flex flex-row justify-between'>
              <Text fz="xs" className='ticketFont'>Forma de pago: </Text>
            
              <Text fz="xs" className='ticketFont text-right capitalize'>{ order.methodPayment}</Text>
            </Box>

          </Box>
        </Box>
        <Divider my={ 2 } className='text-gray-300'/>
        <TicketFooter />
      </Box>
      <Divider my={ 2 } className='text-gray-300'/>
    </div>
  )
}

export default Ticket