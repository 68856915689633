import dayjs from "dayjs";
import { obtenerIniciales } from "../../utils/functions/generateInitNames";
import { Card, Box, Divider, Text, Tooltip, Flex, Group, Avatar } from '@mantine/core'
import { PiChairDuotone, PiMotorcycleDuotone, PiBagDuotone } from "react-icons/pi";
import relativeTime from "dayjs/plugin/relativeTime";
import locale_es from "dayjs/locale/es-mx";
import ProductRow from "./ProductRow";

dayjs.extend(relativeTime);
const OrderView = ({ order, cancel, update }) =>
{

  const changeStatus = (product) =>
  {
    const productsToUpdate = order.orderProducts.filter(
      (item) => item.id !== product.id
    );
    product.statusProductOrder = true;
    productsToUpdate.push(product);
    update(order._id, productsToUpdate);
  };
  if (order.status === "open") {
    return (
      <Card
        withBorder
        shadow="sm"
        radius="lg"
        px="xs"
      >
        <div className="flex flex-row items-center justify-between">
          <Group className="flex flex-row items-center justify-between">
            { order?.orderType === "local" && (
              <PiChairDuotone className="text-2xl" />
            ) }
            { order?.orderType === "delivery" && (
              <PiMotorcycleDuotone className="text-2xl" />
            ) }
            { order?.orderType === "pickup" && (
              <PiBagDuotone className="text-xl" />
            ) }
            <Text fz="xs">
              { dayjs().locale(locale_es).to(dayjs(order.date)) }
            </Text>
          </Group>
          <Box>
            <Tooltip
              label={ order?.user?.name }
              aria-label="Nombre de quien tomo la orden"
            >
              <Avatar name={ order?.user?.name } size="sm" color="blue" variant="filled" >{obtenerIniciales(order?.user?.name)}</Avatar>
            </Tooltip>
          </Box>
        </div>
        <Flex justify="space-between" align="center" mt="sm">
          <Text fz="sm">
            { order.orderInfo?.table }
          </Text>
          <Text fz="sm">
            { order.orderInfo?.name }
          </Text>

        </Flex>
        <Divider my="sm" />
    

          { order.orderProducts.filter(product => product.preparationArea === 'BAR').map((product, index) => (
            <>
            <ProductRow key={ index } product={ product } changeStatus={ changeStatus } />
            <Divider my="sm" variant="dashed" />
            </>
          )) }

      </Card>
    );
  }
};

export default OrderView;
