
import { Text, Box } from '@mantine/core'

const TicketResumenRow = ({ product }) =>
{
  return (
    <>

      <Box className='w-full flex flex-row justify-between items-center my-1'>
        <div className='flex flex-row gap-1 w-full items-center'>
          <Text className='text-xs ticketFont w-full'>{ product.quantity }x  { product._id.name }</Text>
        </div>
        <div className='flex flex-row gap-2  w-4/12 text-right justify-end'>
          <Text className='text-xs ticketFont text-right'>$ { (product.price * product.quantity).toFixed(2) }</Text>
        </div>

      </Box>
      <Box className='w-full flex flex-row justify-between items-center my-1'>

        { product.extras && product.extras.map((extra, index) => (
          <div key={ index }>
            <div className='flex flex-row gap-1 w-full items-center'>
              <Text fz="xs" fw="normal" px="xs">+ { extra.quantity } x { extra.name }</Text>
            </div>
            <div className='flex flex-row gap-2  w-3/12'>
              <Text className='text-xs ticketFont'>$ { (extra.price * extra.quantity).toFixed(2) }</Text>
            </div>
          </div>
        )) }
      </Box>
    </>
  )
}

export default TicketResumenRow