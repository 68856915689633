import React, { useState, useEffect } from 'react'
import { get_sales, send_sales_report } from '../../utils/apis/reports'
import { Title, Button, Text, Box, Divider, Input } from '@mantine/core';
import dayjs from 'dayjs';
import DailyRow from '../../components/Reports/Daily/DailyRow';
import BoxSummary  from '../../components/Dashboard/BoxSummary'
const Daily = () =>
{
  const [ items, setItems ] = useState();
  const [ dateStart, setDateStart ] = useState(null);
  const [ dateEnd, setDateEnd ] = useState(null);
  const [ summary, setSummary ] = useState({
    subtotal: 0,
    discount: 0,
    tax: 0,
    cash:0,
    creditCard:0,
    total: 0
  })

  useEffect(() =>
  {
    (async () =>
    {
      setDateStart(dayjs().format('YYYY-MM-DD'))
      setDateEnd(dayjs().format('YYYY-MM-DD'))

      await getSalesReport(dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'))
    })()
  }, [])

  const getSalesReport = async (start, end) =>
  {
    const date1 = dayjs(start).startOf('day').toISOString();
    const date2 = dayjs(end).endOf('day').toISOString()
    console.log(date1, ' ', date2)
    const res = await get_sales({ dateStart: date1, dateEnd: date2 });
    setItems(res.results.orders);
    setSummary({
      subtotal:res?.results.summary.subTotal,
      discount: res?.results.summary.discount,
      tax:res?.results.summary.tax,
      cash:res?.results.summary.cash,
      creditCard:res?.results.summary.creditCard,
      total:res?.results.summary.total
    })
  }

  const sendSalesInfo = async (start, end) =>
  {
    const date1 = dayjs(start).toISOString();
    const date2 = dayjs(end).toISOString()
    await send_sales_report({ dateStart: date1, dateEnd: date2 });

  }

  return (
    <>
      <div className='flex flex-col lg:flex-row justify-between items-stretch lg:items-center gap-2'>
        <Title order={1}>Reporte de Ventas</Title>


        <Box className='flex flex-col lg:flex-row gap-2 print:hidden '>
          <Box className='flex flex-row gap-1 items-center'>
            <Text>De: </Text>
            <Input  w={'100%'} variant='filled' size='md' radius="md" type='date' value={ dateStart } onChange={ (e) => setDateStart(e.target.value) } />
          </Box>
          <Box className='flex flex-row gap-1 items-center'>
            <Text>a: </Text>
            <Input w={'100%'} variant='filled' size='md' radius="md" type='date' value={ dateEnd } onChange={ (e) => setDateEnd(e.target.value) } />
          </Box>
          <Button size='md' radius="md" variant='filled' color='blue' onClick={ () => getSalesReport(dateStart, dateEnd) }>Buscar</Button>
          <Button size='md' radius="md" variant='light' color='green' onClick={ () => sendSalesInfo(dateStart, dateEnd) }>Enviar Reporte</Button>
        </Box>
      </div>
      <Box className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2'>
      <BoxSummary title={'Subtotal'} value={ summary.subtotal ? `$ ${summary.subtotal.toFixed(2)}` : '$ 0.00'} color='white' />
      <BoxSummary title={'Descuento'} value={summary.discount ? `$ ${summary.discount.toFixed(2)}` : '$ 0.00'} color='white' />
      <BoxSummary title={'IVA'} value={summary.tax ? `$ ${summary.tax.toFixed(2)}` : '$ 0.00'} color='white' />
      <BoxSummary title={'Efectivo'} value={ summary.cash ? `$ ${summary.cash.toFixed(2)}` : '$ 0.00'} color='white' />
      <BoxSummary title={'Tarjeta'} value={ summary.creditCard ? `$ ${summary.creditCard.toFixed(2)}` : '$ 0.00'} color='white' />
      <BoxSummary title={'Total'} value={ summary.total ? `$ ${summary.total.toFixed(2)}` : '$ 0.00'} color='white' />
      </Box>
      <Title order={2} my="md">Ordenes</Title>
      <Box className='print:block"'>
        {
          items ? items.map(order => (
            <DailyRow key={ order._id } order={ order } />
          )) :
            null
        }
      </Box>
    </>
  )
}

export default Daily