export function obtenerIniciales(nombre) {
  if (typeof nombre !== 'string') {
    // Retorna un valor predeterminado o maneja el error como prefieras
    console.error('nombre debe ser una cadena');
    return '';
  }

  return nombre
    .split(' ') // Divide el nombre en un array de palabras
    .filter(palabra => palabra.length > 0) // Filtra los espacios en blanco extras
    .map(palabra => palabra[0].toUpperCase()) // Obtiene la primera letra de cada palabra y la convierte a mayúscula
    .join(''); // Une todas las primeras letras en una cadena
}