import { Tabs, TextInput, Select, Box } from '@mantine/core'
const OrderInfo = ({ orderInfo, chanegOrderInfo, tables, tabIndex, handleTabsChange }) =>
{


  return (
    <Box>
      <Tabs radius="md" defaultValue={ tabIndex ? tabIndex : 'local' } onChange={ (e) => handleTabsChange(e) }>
        <Tabs.List>
          <Tabs.Tab value="local">Mesa</Tabs.Tab>
          <Tabs.Tab value="pickup">Para Llevar</Tabs.Tab>
          <Tabs.Tab value="delivery">A domicilio</Tabs.Tab>
          <Tabs.Tab value="uberEats">Uber Eats</Tabs.Tab>
          <Tabs.Tab value="rappi">Rappi</Tabs.Tab>
          <Tabs.Tab value="didi">Didi Food</Tabs.Tab>
        </Tabs.List>


        <Tabs.Panel value="local" className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
          <Select size='md' variant='filled' radius="md" label="Mesa" name="table" className='mx-0 md:mx-2' value={ orderInfo.table } onChange={ (e) => chanegOrderInfo("table", e) }
            data={ tables && tables.map((table) => (table.name)) }
          />
          <TextInput size='md' variant='filled' radius="md" label="Nombre" name='name' value={ orderInfo.name } placeholder='Nombre' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('name', e.target.value) } />
          <TextInput size='md' variant='filled' radius="md" label="No. personas" name="persons" value={ orderInfo.persons } placeholder='# de personas' type='number' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('persons', e.target.value) } />
        </Tabs.Panel>
        <Tabs.Panel value="pickup" className='grid grid-cols-1 md:grid-cols-2'>
          <TextInput size='md' variant='filled' radius="md" label="Nombre" name="name" value={ orderInfo.name } placeholder='Nombre' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('name', e.target.value) } />

          <TextInput label="Teléfono" size='md' variant='filled' radius="md" name="phone" value={ orderInfo.phone } placeholder='Teléfono' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('phone', e.target.value) } />
        </Tabs.Panel>
        <Tabs.Panel value="delivery" className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3'>

          <TextInput size='md' variant='filled' radius="md" label="Nombre" name='name' value={ orderInfo.name } placeholder='Nombre' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('name', e.target.value) } />

          <TextInput size='md' variant='filled' radius="md" label="Teléfono" name="phone" value={ orderInfo.phone } placeholder='Teléfono' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('phone', e.target.value) } />

          <TextInput size='md' variant='filled' radius="md" label="Dirección" name="address" value={ orderInfo.address } placeholder='Dirección' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('address', e.target.value) } />

        </Tabs.Panel>
        <Tabs.Panel value="uberEats" className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3'>

          <TextInput size='md' variant='filled' radius="md" label="Nombre" name='name' value={ orderInfo.name } placeholder='Nombre' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('name', e.target.value) } />

          <TextInput size='md' variant='filled' radius="md" label="Teléfono" name="phone" value={ orderInfo.phone } placeholder='Teléfono' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('phone', e.target.value) } />

          <TextInput size='md' variant='filled' radius="md" label="Dirección" name="address" value={ orderInfo.address } placeholder='Dirección' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('address', e.target.value) } />

        </Tabs.Panel>
        <Tabs.Panel value="rappi" className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3'>

          <TextInput size='md' variant='filled' radius="md" label="Nombre" name='name' value={ orderInfo.name } placeholder='Nombre' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('name', e.target.value) } />

          <TextInput size='md' variant='filled' radius="md" label="Teléfono" name="phone" value={ orderInfo.phone } placeholder='Teléfono' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('phone', e.target.value) } />

          <TextInput size='md' variant='filled' radius="md" label="Dirección" name="address" value={ orderInfo.address } placeholder='Dirección' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('address', e.target.value) } />

        </Tabs.Panel>
        <Tabs.Panel value="didi" className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3'>

          <TextInput size='md' variant='filled' radius="md" label="Nombre" name='name' value={ orderInfo.name } placeholder='Nombre' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('name', e.target.value) } />

          <TextInput size='md' variant='filled' radius="md" label="Teléfono" name="phone" value={ orderInfo.phone } placeholder='Teléfono' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('phone', e.target.value) } />

          <TextInput size='md' variant='filled' radius="md" label="Dirección" name="address" value={ orderInfo.address } placeholder='Dirección' type='text' className='mx-0 md:mx-2' onChange={ (e) => chanegOrderInfo('address', e.target.value) } />

        </Tabs.Panel>
      </Tabs>
    </Box>

  )
}

export default OrderInfo