import { Modal, Button, Title, Flex } from '@mantine/core';
import { delete_transaction } from '../../utils/apis/transactions';

const Confirm = ({ show, setShow, onClose, item }) =>
{

  const removeOrder = async (id) =>
  {
    await delete_transaction(id)
    onClose()
  }
  return (
    <Modal opened={ show } onClose={ onClose } centered title={ <Title order={ 3 }>Confirmar eliminación</Title> }>
      <Flex direction="column" gap="md" my='md'>
      <p>{`¿Estás seguro de eliminar la venta de `} <strong>{`$ ${item?.total?.toFixed(2)}`}</strong> ?</p>
      </Flex>
      <Flex gap="md"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap">

        <Button onClick={ onClose } color="blue" variant="light">Cerrar</Button>
        <Button onClick={ ()=>{removeOrder(item._id)} } color="red" variant="filled">Eliminar</Button>
      </Flex>

    </Modal>
  )
}


export default Confirm;
