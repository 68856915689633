import { useFormik } from 'formik';
import { Modal, Flex, Button, Select, Text, TextInput, Group, rem, Box, ActionIcon, Image, Tooltip } from '@mantine/core';
import { PiUpload, PiImage, PiX, PiTrashDuotone } from 'react-icons/pi';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import * as Yup from 'yup';
import { post_slider } from '../../utils/apis/sliders';

const UploadSlider = ({ id, show, setShow, onClose }) =>
{

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    onSubmit: async (data) =>
    {
      console.log(data);
      let slider = new FormData();
      slider.append('file', data.image);
      // Agregar datos JSON al FormData
      const jsonData = {
        title: data.title,
        section: data.section,
      };
      slider.append('data', JSON.stringify(jsonData));

      await post_slider(slider);
      formik.resetForm();
      onClose()
    },
  });

  return (
    <Modal opened={ show } onClose={ setShow } title={ <Text fz="lg" fw="bold">Cargar nuevo slider</Text> } centered>

      { formik.values.image ?
        <Box className='relative w-full' h={ 200 } mb="md" >
          <Flex justify="flex-end">
            <Tooltip label="Eliminar imagen" position="left" withArrow>
              <ActionIcon variant="filled" radius="md" color="red" className='absolute top-2 right-2 z-10'>
                <PiTrashDuotone />
              </ActionIcon>
            </Tooltip>
            { formik.values.image && (
              <Image
                radius="lg"
                mb="md"
                fit="contain"
                className='absolute z-0'
                src={ URL.createObjectURL(formik.values.image) }
                alt="preview"
                style={ { width: '100%', height: 200 } }
              />
            ) }
          </Flex>
        </Box> :
        <Dropzone
          onDrop={ (files) => formik.setFieldValue('image', files[ 0 ]) }
          onReject={ (files) => console.log('rejected files', files) }
          maxSize={ 5 * 1024 ** 2 }
          accept={ IMAGE_MIME_TYPE }
        >
          <Group justify="center" gap="xl" mih={ 160 } style={ { pointerEvents: 'none' } }>
            <Dropzone.Accept>
              <PiUpload
                style={ { width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' } }
                stroke={ 1.5 }
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <PiX
                style={ { width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' } }
                stroke={ 1.5 }
              />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <PiImage
                style={ { width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' } }
                stroke={ 1.5 }
              />
            </Dropzone.Idle>

            <div>
              <Text size="lg" inline>

                Arrastra la imagen aquí o haz clic para seleccionar la imagen.
              </Text>
              <Text size="sm" c="dimmed" inline mt={ 7 }>

                Adjunta un archivo no debe superar los 5 MB.
              </Text>
            </div>
          </Group>
        </Dropzone>
      }
      <TextInput
        size='lg'
        radius='md'
        variant='filled'
        label="Descripción de la imagen"
        onChange={ (e) => formik.setFieldValue('title', e.target.value) }
        type="text"
        placeholder='Descripción de la imagen'
      />

      <Select
        size='lg'
        radius='md'
        variant='filled'
        label="Ubicación de la imagen"
        value={ formik.values.section }
        placeholder='Ubicación'
        data={ [ { value: 'banner', label: 'Banner' }, { value: 'gallery', label: 'Menu' } ] }
        onChange={ (e) => formik.setFieldValue('section', e) } />

      <Flex justify="flex-end" align="center" gap="md" my="sm">

        <Button variant='light' size="md" radius="md" color='gray' mr={ 3 } onClick={ () => setShow(!show) }>
          Cancelar
        </Button>
        <Button variant='filled' size="md" radius="md" color='blue' onClick={ () => formik.handleSubmit() }>Agregar</Button>

      </Flex>
    </Modal>
  );
};

export default UploadSlider;

function initialValues ()
{
  return {
    slider: null,
    section: 'banner',
    title: ''
  };
}

function validationSchema ()
{
  return Yup.object().shape({
    image: Yup.mixed().required('Debes seleccionar una imagen'),
    section: Yup.string().required('Es requerida la sección'),
    title: Yup.string().required('El título es requerido'),
  });
}
