import React from 'react'
import dayjs from 'dayjs'
import { Table, Card, Title } from '@mantine/core';

const OrderSummary = ({ orderInfo, orderDetail }) =>
{

  return (
    <>
      <Card withBorder shadow='md' my="md" radius="xl" >
        <Title order={ 4 }>Datos de la orden</Title>
        <Table>
          <Table.Tbody>
            <Table.Tr>

              <Table.Td colSpan={ 2 } className='text-right'>{ dayjs(orderDetail?.date).format('DD/MM/YYYY HH:mm a') }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Mesa:</Table.Td>
              <Table.Td>{ orderInfo.table }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Cliente:</Table.Td>
              <Table.Td>{ orderInfo.name }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Personas:</Table.Td>
              <Table.Td>{ orderInfo.persons }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Fecha de entrega:</Table.Td>
              <Table.Td>{ orderInfo.pickDate }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Hora:</Table.Td>
              <Table.Td>{ orderInfo.pickUpTime }</Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>Tel:</Table.Td>
              <Table.Td>{ orderInfo.phone }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Dirección:</Table.Td>
              <Table.Td>{ orderInfo.address }</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Card>
    </>
  )
}

export default OrderSummary