import { Text, Modal, Alert } from '@mantine/core'

const ModalExpired = ({ show, expired }) =>
{

  return (
    <Modal closeOnEscape={false} closeOnClickOutside={false}  radius="lg" size="xl" opened={ show } withCloseButton={ false } centered >
      <Alert color="red" title={ <Text fz="xl" fw="bold">Aviso importante</Text> } shadow="xl"  >
      
        <Text fz="lg" fw="lighter" className='text-left'>Favor de realizar el pago correspondiente y contactar a <span className='font-bold'>miguel@pixel-label.com</span> para notificar el pago anexando el comprobante</Text>

      </Alert>
    </Modal>
  )
}


export default ModalExpired;
