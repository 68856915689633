import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Box, Flex, Text, Button, ActionIcon, Textarea, Select, Title, NumberInput, TextInput } from '@mantine/core';
import { PiArrowLeft, PiMinus, PiPlus } from 'react-icons/pi'
import { get_product, post_product, update_product } from '../../../utils/apis/products';
import { get_categories } from '../../../utils/apis/categories';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ExtrasSection from '../../../components/Products/ExtrasSection';

const Product = () =>
{
  const navigate = useNavigate();
  const [ categories, setCategories ] = useState();
  const [ _id, setId ] = useState(null)
  const [ extras, setExtras ] = useState([])
  const [ preview, setPreview ] = useState(null)
  let { id } = useParams()

  useEffect(() =>
  {
    (async () =>
    {
      const resCat = await get_categories();
      setCategories(resCat.results)
      if (id) {
        setId(id)
        const { results } = await get_product(id);
        setExtras(results[ 1 ] ? results[ 1 ] : [])  
        formik.setFieldValue('name', results[ 0 ].name);
        formik.setFieldValue('description', results[ 0 ].description);
        formik.setFieldValue('price', results[ 0 ].price);
        formik.setFieldValue('qty', results[ 0 ].qty);
        formik.setFieldValue('status', results[ 0 ].status === true ? 'visible' : 'hide')
        formik.setFieldValue('inventoryStatus', results[ 0 ].inventoryStatus === true ? 'si' : 'no')
        formik.setFieldValue('recommended', results[ 0 ].recommended === true ? 'si' : 'no')
        formik.setFieldValue('category', results[ 0 ].category._id);
        formik.setFieldValue('preparationArea', results[ 0 ].preparationArea)
        setPreview(results[ 0 ].image)

      }
      console.log(extras)
    })()
  }, [])


  const addToInventory = (e) =>
  {
    console.log(typeof formik.values.qty)
    formik.setFieldValue('qty', formik.values.qty + 1)
  }
  const removeToInventory = (e) =>
  {
    formik.setFieldValue('qty', formik.values.qty - 1)
  }
  const resteFormikValue = async () =>
  {
    formik.resetForm()
  }
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {

      const product = {
        name: data.name,
        description: data.description,
        price: data.price,
        category: data.category,
        preparationArea: data.preparationArea,
        qty: data.qty,
        status: data.status === 'visible' ? true : false,
        inventoryStatus: data.inventoryStatus === 'si' ? true : false,
        image: data.image,
        recommended: data.recommended === 'si' ? true : false
      }
      if (_id) {
        product._id = _id
        await update_product(_id, product)
      } else {
        await post_product(product)
      }
      formik.resetForm()
      navigate(-1)

    }
  })
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col md:flex-row items-center justify-between gap-3'>
        <div className='flex flex-row items-center justify-start gap-2 w-full md:w-auto'>
          <ActionIcon variant="subtle" size="md" c="gray.9" aria-label='Regresar' onClick={ () => navigate(-1) } >
            <PiArrowLeft className='text-2xl' />
          </ActionIcon>
          <Title order={ 1 }>{ _id ? formik.values.name : 'Crear un producto' }</Title>
        </div>
        <div className='flex flex-col md:flex-row gap-2 w-full md:w-auto '>
          <Button size="lg" radius="md" color='gray' variant="light" onClick={ () => navigate(-1) } >Descartar</Button>
          <Button size="lg" radius="md" color='blue' onClick={ () => formik.handleSubmit() }>Guardar</Button>
        </div>
      </div>
      <Flex direction={ { base: 'column', md: 'row' } } gap="md" >
        <Flex direction="column" gap="md" w={ '100%' }>
          <Card shadow='md' withBorder px="md" py="md" radius="lg" >

            <TextInput size='lg' radius="md" variant='filled' label="Nombre del producto" placeholder='Nombre del producto' value={ formik.values.name } onChange={ (e) => formik.setFieldValue('name', e.target.value) } />

            <Textarea size='lg' radius="md" variant='filled' label="Descripción" placeholder='' value={ formik.values.description } onChange={ (e) => formik.setFieldValue('description', e.target.value) } />

          </Card>
          <Card shadow='md' withBorder px="md" py="md" radius="lg" >
            <NumberInput label="Precio de venta" size='lg' radius="md" variant='filled' leftSection="$" placeholder='0' value={ formik.values.price } onChange={ (e) => formik.setFieldValue('price', e) } />
          </Card>
          <Card shadow='md' withBorder px="md" py="md" radius="lg" >
            <Text mb='8px' fontSize='lg'>Inventario</Text>
            <div className='flex flex-col md:grid md:grid-cols-2 gap-2 justify-between items-start md:items-center'>
              <div className='w-full'>
                <Select
                  variant='filled'
                  size='lg'
                  radius="md"
                  value={ formik.values.inventoryStatus }
                  label="Dar seguimiento"
                  placeholder="Selecciona una opción"
                  onChange={ (e) => formik.setFieldValue('inventoryStatus', e) }
                  data={ [ 'si', 'no' ] }
                />
              </div>
              { formik.values.inventoryStatus === 'si' && <div className='w-full'>

                <div className='flex flex-row gap-1 items-end'>
                  <ActionIcon size="xl" aria-label='Restar' onClick={ removeToInventory } disabled={ formik.values.qty === 0 ? true : false } >
                    <PiMinus />
                  </ActionIcon>
                  <NumberInput
                    label="Cantidad"
                    placeholder=''
                    value={ formik.values.qty }
                    variant='filled'
                    size='lg'
                    radius="md"
                    onChange={ (e) => formik.setFieldValue('qty', e) } />
                  <ActionIcon size="xl" aria-label='Agregar' onClick={ addToInventory } >
                    <PiPlus />
                  </ActionIcon>
                </div>
              </div> }
            </div>
          </Card>
        </Flex>
        <Flex direction="column" gap="md" className='w-12/12 md:w-6/12'>
          <Card shadow='md' withBorder px="md" py="md" radius="lg" >
            <Select
              variant='filled'
              size='lg'
              radius="md"
              value={ formik.values.status }
              label="Visibilidad"
              placeholder="Selecciona una opción"
              onChange={ (e) => formik.setFieldValue('status', e) }
              data={ [ {
                value: 'visible',
                label: 'Visible'
              }, {
                value: 'hide',
                label: 'Oculto'
              } ] }
            />

            <Select
              variant='filled'
              size='lg'
              radius="md"
              value={ formik.values.recommended }
              label="Producto recomendado"
              placeholder="Selecciona una opción"
              onChange={ (e) => formik.setFieldValue('recommended', e) }
              data={ [
                {
                  value: 'si',
                  label: 'Si'
                },
                {
                  value: 'no',
                  label: 'No'
                }
              ] }
            />

          </Card>

          <Card shadow='md' withBorder px="md" py="md" radius="lg" >

            <Select
              variant='filled'
              size='lg'
              radius="md"
              searchable
              checkIconPosition="right"
              value={ formik.values.category }
              label="Categoría"
              placeholder="Selecciona una opción"
              onChange={ (e) => formik.setFieldValue('category', e) }
              data={ categories && categories.map((category) => (
                {
                  value: category._id,
                  label: category.name
                }
              )) }
            />
          </Card>
          <Card shadow='md' withBorder px="md" py="md" radius="lg" >
            <Select
              variant='filled'
              size='lg'
              radius="md"
              value={ formik.values.preparationArea }
              label="Área de preparación"
              placeholder="Selecciona una opción"
              onChange={ (e) => formik.setFieldValue('preparationArea', e) }
              data={ [ {
                value: 'KITCHEN',
                label: 'Cocina'
              }, {
                value: 'BAR',
                label: 'Barra'

              } ] }
            />

          </Card>
          {/* <Box w='100%' p={4} borderWidth='1px' borderRadius='lg' >
            <Text mb='8px' fontSize='lg'>Imagen del producto</Text>
            <Input size='lg' p={2} type='file' value={formik.values.image} onChange={(e) => formik.setFieldValue('image', e.target.value)} />
            {preview &&
              <Box className='flex flex-row items-center justify-center my-2'>
                <img src={preview} alt='imagen' className='w-32 h-32 object-fill' />
              </Box>}
          </Box> */}
        </Flex>
      </Flex>
    
        <Card shadow='md' withBorder px="md" py="md" radius="lg" className='w-full lg:w-8/12'>
          <ExtrasSection extras={extras} productId={_id}/>
        </Card>

    </div>
  )
}

export default Product;

function initialValues ()
{
  return {
    name: '',
    status: 'visible',
    image: '',
    inventoryStatus: 'no',
    qty: 0,
    description: '',
    price: '',
    category: '',
    recommended: 'no',
    preparationArea: 'KITCHEN'
  }
}

function validationSchema ()
{
  return {
    name: Yup.string().required(true),
    status: Yup.string(),
    image: Yup.string(),
    preparationArea: Yup.string(),
    inventoryStatus: Yup.string(),
    qty: Yup.number(),
    description: Yup.string(),
    price: Yup.number().required(true),
    category: Yup.string().required(true),
    recommended: Yup.string()


  }
}