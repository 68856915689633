import { Modal, Box, Button } from '@mantine/core';
import QRCode from "react-qr-code";

const QrOrden = ({ show, setShow, orderId}) => {

  return (
    <Modal opened={ show } onClose={ setShow } centered>


        { orderId && <Box w='100%' p={ 4 }  className="grid grid-cols-1 place-items-center" >
            <QRCode value={ `https://www.laxulapulqueria.com/#/orders/${ orderId }` } className='rounded-lg w-full h-auto' />
          </Box> }
    </Modal>
  )
}


export default QrOrden;
