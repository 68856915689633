import { Card, Box, Text, TextInput, Title } from '@mantine/core';
import { PiMapPin, PiPhone, PiChat } from 'react-icons/pi';

const WhatsappView = ({ localSettings, handleInputChange }) =>
{
  return (
    <Card shadow='md' px="md" py="sm" my="md" withBorder radius="lg">
      <Box>
        <Title order={ 2 }>Whatsapp</Title>
      </Box>

      { localSettings.whatsapps && localSettings.whatsapps.map((item, index) => (
        <Box key={ index } className='flex flex-col gap-2'>
          <Text>{ item.name }</Text>
          <Box className='flex flex-row gap-1 items-center w-full'>
            <PiMapPin />
            <TextInput
            className='w-full'
              placeholder='sucursal'
              value={ item.name }
              onChange={ (e) => handleInputChange(index,'name', e.target.value) }
            />
          </Box>
          <Box className='flex flex-row gap-1 items-center w-full'>
            <PiChat />
            <TextInput
            className='w-full'
              placeholder='mensaje'
              value={ item.msg }
              onChange={ (e) => handleInputChange(index,'msg', e.target.value) }
            />
          </Box>
          <Box className='flex flex-row gap-1 items-center w-full'>
            <PiPhone />
            <TextInput
            className='w-full'
              placeholder='Numero'
              value={ item.number }
              onChange={ (e) => handleInputChange(index,'number', e.target.value) }
            />
          </Box>
        </Box>
      )) }

    </Card>
  )
}

export default WhatsappView