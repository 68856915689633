import React from 'react'
import { Grid } from '@mantine/core'

const BoxGrid = ({ children }) =>
{
  return (
    <Grid>
     { children}
    </Grid>
  )
}

export default BoxGrid