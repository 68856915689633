/* eslint-disable import/no-anonymous-default-export */
import {
    LIST_SETTINGS,
    ADD_SETTING,
    UPDATE_SETTING
} from '../types';



export default (state, action) => {
    switch (action.type) {

        case LIST_SETTINGS:
            localStorage.setItem('settings',JSON.stringify(action.payload))
            return {
                settings: action.payload,
                loading:false
            }
        case UPDATE_SETTING:    
        case ADD_SETTING:
            return {
                loading:true
            }

        default:
            console.log('default')
            return state;

    }
}