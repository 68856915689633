import BoxSummaryOutline from "../BoxSummaryOutline"

const DailyGrid = ({totalOpen, totalClosed, totalEarn, totalProducts, totalTransactions, dailyOrderType, methodAmount}) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mb-2">
    <BoxSummaryOutline
      title="Abiertas"
      value={ totalOpen ? totalOpen : "0" }
      color="teal"
      amount={ false }
    />

    <BoxSummaryOutline title="Cerradas" value={ totalClosed } color="cyan" amount={ false } />
    <BoxSummaryOutline
      title="Productos vendidos"
      value={ totalProducts }
      color="purple"
      amount={ false }
    />
    <BoxSummaryOutline
      title="Local"
      value={ dailyOrderType?.local.totalTransactions ? dailyOrderType?.local.totalTransactions : "0" }
      color="black"
      amount={ false }
    />
    <BoxSummaryOutline
      title="Delivery"
      value={ dailyOrderType?.delivery.totalTransactions ? dailyOrderType?.delivery.totalTransactions : "0" }
      color="black"
      amount={ false }
    />
    <BoxSummaryOutline
      title="Pickup"
      value={ dailyOrderType?.pickup.totalTransactions ? dailyOrderType?.pickup.totalTransactions : "0" }
      color="black"
      amount={ false }
    />
    <BoxSummaryOutline
      title={ "Efectivo" }
      value={
        methodAmount.efectivo
          ? `$ ${ methodAmount.efectivo }`
          : "$ 0.00"
      }
      color="green"
    />
    <BoxSummaryOutline
      title={ "Tarjeta" }
      value={
        methodAmount.tarjeta
          ? `$ ${ methodAmount.tarjeta }`
          : "$ 0.00"
      }
      color="pink"
    />
    <BoxSummaryOutline
      title="Total de ventas"
      value={ totalEarn ? `$ ${ totalEarn }` : "$ 0.00" }
      color="blue"
    />
    <BoxSummaryOutline
      title={ "Comisión de tarjeta" }
      value={
        methodAmount.tarjeta
          ? `$ ${ (methodAmount.tarjeta * 0.04) }`
          : "$ 0.00"
      }
      color="orange"
    />
    <BoxSummaryOutline
      title={ "Tarjeta - Comisión" }
      value={
        methodAmount.tarjeta
          ? `$ ${ methodAmount.tarjeta - (methodAmount.tarjeta * 0.04) }`
          : "$ 0.00"
      }
      color="yellow"
    />

    <BoxSummaryOutline
      title={ "Total de ventas - comisiones" }
      value={
        methodAmount.tarjeta
          ? `$ ${ totalEarn ? `$ ${ totalEarn - (methodAmount.tarjeta * 0.04) }` : "$ 0.00" }`
          : "$ 0.00"
      }
      color="gray"
    />
  </div>
  )
}

export default DailyGrid