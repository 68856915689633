import React, { useContext } from "react";
import { Alert, Box, Text } from "@mantine/core";
import { PiInfoDuotone } from "react-icons/pi";
import AlertContext from "../../contexts/Alert/alertContext";

export const AlertaPermisos = ({ alerta }) =>
{
  const alertContext = useContext(AlertContext);
  const { hideAlert } = alertContext
  return (
    <Box className="absolute top-1 right-1">
      <Alert onClose={hideAlert} autoContrast variant="filled" withCloseButton color="yellow" radius="lg" title={ alerta.title } icon={ <PiInfoDuotone className="text-xl" /> } >
        <Text mx="xs">{ alerta.msg }</Text>
      </Alert>

    </Box>
  );
};
