import React, { useState, useEffect } from 'react'
import { Flex, Modal, Button, Switch, TextInput, Checkbox, Text, ScrollArea } from '@mantine/core';

import { useFormik } from 'formik'
import * as Yup from 'yup'

const ModalUpdateUser = ({ user, show, setShow, updateUser }) =>
{
  const [ id, setId ] = useState(null)
  const handleCheckboxChange = (event) =>
  {
    formik.setFieldValue('role', event);
  };
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object().shape(validationSchema()),
    onSubmit: async (data) =>
    {
      const user = {
        _id: id,
        name: data.name,
        email: data.email,
        role: data.role,
        status: data.status
      }
      await updateUser(user)
      formik.resetForm()
      setShow()

    }
  })
  useEffect(() =>
  {
    if (user) {
      setId(user.uid)
      formik.setFieldValue('name', user?.name);
      formik.setFieldValue('email', user?.email);
      formik.setFieldValue('role', user?.role);
      formik.setFieldValue('status', user?.status);
    }
  }, [ user ])

  return (
    <Modal opened={ show } onClose={ setShow } title={ <Text fz="lg" fw="bold">Actualizar { user?.name }</Text> } centered>
      <ScrollArea type="scroll">
        <TextInput
          variant='filled'
          radius='lg'
          size='lg'
          label="Nombre" placeholder='Nombre' value={ formik.values.name } onChange={ (e) => formik.setFieldValue('name', e.target.value) } isInvalid={ formik.errors.name }
          errorBorderColor='red.300' />

        <TextInput
          variant='filled'
          radius='lg'
          size='lg'
          label="Correo" placeholder='Email' value={ formik.values.email } onChange={ (e) => formik.setFieldValue('email', e.target.value) } isInvalid={ formik.errors.email }
          errorBorderColor='red.300' />
        <Text fz="lg" my="sm">Permisos</Text>
        <Flex direction="row" wrap="wrap" align="flex-start" gap="md" my="md">
          <Checkbox.Group value={ formik.values.role } onChange={ handleCheckboxChange } gap="md" size="sm">
            <Checkbox
              label="ADMIN"
              value="ADMIN"
              checked={ formik.values.role.includes('ADMIN') }

            >
              Admin
            </Checkbox>
            <Checkbox
              label="WAITER"
              value="WAITER"
              checked={ formik.values.role.includes('WAITER') }

            >
              Mesero
            </Checkbox>
            <Checkbox
              label="CASHIER"
              value="CASHIER"
              checked={ formik.values.role.includes('CASHIER') }

            >
              Cajero
            </Checkbox>
            <Checkbox
              label="MANAGER"
              value="MANAGER"
              checked={ formik.values.role.includes('MANAGER') }

            >
              Manager
            </Checkbox>
            <Checkbox
              label="KITCHEN"
              value="KITCHEN"
              checked={ formik.values.role.includes('KITCHEN') }

            >
              Cocina
            </Checkbox>
            <Checkbox
              label="BAR"
              value="BAR"
              checked={ formik.values.role.includes('BAR') }

            >
              Barra
            </Checkbox>
          </Checkbox.Group>
        </Flex>
        <Flex justify="flex-end" align="center" gap="md" my="sm">
          <Switch
            checked={ formik.values.status }
            labelPosition="left"
            size="xl"
            color="green"
            onLabel="Activo" offLabel="Inactivo"
            onChange={ (e) => formik.setFieldValue('status', e.currentTarget.checked) }
          />
        </Flex>
      </ScrollArea>


      <Flex justify="flex-end" align="center" gap="md" my="sm">

        <Button variant='light' size="md" radius="md" color='gray' mr={ 3 } onClick={ () => setShow(!show) }>
          Cancelar
        </Button>
        <Button variant='filled' size="md" radius="md" color='blue' onClick={ () => formik.handleSubmit() }>Agregar</Button>

      </Flex>
    </Modal>
  )
}


export default ModalUpdateUser;

function initialValues ()
{
  return {
    name: '',
    email: '',
    role: [],
    status: true

  }
}

function validationSchema ()
{
  return {
    name: Yup.string().required(true, 'El nombre es obligatorio'),
    email: Yup.string().required(true, 'El correo es obligatorio'),
    role: Yup.array().min(1, 'El rol es obligatorio').required('El rol es obligatorio'),
    status: Yup.boolean()
  }
}