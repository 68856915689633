import React, { useContext, useState, useEffect } from 'react'
import UserContext from '../../contexts/Users/userContext';
import { Text, Button, Title } from '@mantine/core';
import UserTable from '../../components/Users/UserTable';
import ModalUser from '../../components/Modal/ModalUser';
import ModalUpdateUser from '../../components/Modal/ModalUpdateUser';
import AuthContext from '../../contexts/Auth/authContext'
import { PiPlus } from 'react-icons/pi';
const Users = () => {
  const userContext = useContext(UserContext)
  const authContext = useContext(AuthContext);
  const {user} = authContext;
  const { total, list, loading, message, getUsersList, deleteUser, restoreUser, updateUser, postNewUser } = userContext;
  const [showModal, setShowModal] = useState(false)
  const [showUpdateUser, setShowUpdateUser] = useState(false)
  const [userUpdate, setUserUpdate] = useState(null)
  useEffect(() => {
    (async () =>
      await getUsersList()
    )()
  }, [])


  useEffect(() => {
    (async () =>
      await getUsersList()
      
   
    )()
    console.log(loading)
  }, [loading]);

  const borrar = async (id) => {
    await deleteUser(id)
  }
  const restore = async (id) => {
    await restoreUser(id)
  }

  const openUpdateUser = (user)=>{
    setUserUpdate(user);
    setShowUpdateUser(true);
  }

  const closeUpdateUser = (user)=>{
    setUserUpdate(null);
    setShowUpdateUser(false);
  }

  return (
    <>
      <div className='grid grid-cols-1 md:flex md:flex-row justify-between items-center gap-2'>
      {message && <Text>{message}</Text>}
        <div className='flex flex-col'>
          <Title order={1}>Usuarios</Title>
          <Title order={5} fw="lighter" className='hidden md:block'>Usuarios Activos:  {total}</Title>
        </div>
        <Button size='md' radius="md" color='blue' onClick={() => setShowModal(!showModal)} leftSection={<PiPlus className='text-xl' />}>Nuevo</Button>
      </div>

      <UserTable items={list} user={user} deleteUser={borrar} restoreUser={restore} openUpdateUser={openUpdateUser}/>
      <ModalUser show={showModal} setShow={setShowModal}  postNewUser={postNewUser}/>
      <ModalUpdateUser show={showUpdateUser} setShow={closeUpdateUser} user={userUpdate} updateUser={updateUser} />
    </>
  )
}

export default Users