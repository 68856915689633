export const getTotal = (products) => {
  let temporalTotal = 0.00;

  for (const product of products) {
    // Agrega el costo del producto (precio * cantidad)
    temporalTotal += product.price * product.quantity;

    // Si el producto tiene extras, suma sus precios al total
    if (product.extras && product.extras.length > 0) {
      for (const extra of product.extras) {
        temporalTotal += (extra.price * (extra.quantity || 1));
      }
    }
  }

  console.log(temporalTotal);
  return temporalTotal;
}

export const getIva = (amount) => {
  return  (amount - (amount / 1.16)).toFixed(2)
}