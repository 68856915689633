import React, { useState, useEffect, useContext } from 'react'
import { Flex, Loader, Title, Box } from '@mantine/core';
import OrdersList from '../../components/Kitchen/OrdersList';
import { SocketContext } from '../../contexts/Socket/socket';
import { get_all_orders, update_order } from '../../utils/apis/orders'
import OrderIconReference from '../../components/Commons/OrderIconReference';

const Kitchen = () =>
{
  const socket = useContext(SocketContext)
  const [ total, setTotal ] = useState(0);
  const [ orders, setOrders ] = useState(null);
  const [ loading, setLoading ] = useState(true)
  const [ orderTypeVisible, setOrderTypeVisible ] = useState('all')
  useEffect(() =>
  {
    getAllOrders()
  }, []);
  useEffect(() =>
  {
    socket.on('listOrders', (payload) =>
    {

      setOrders(payload.orders);
      setTotal(payload.total);
    })
  }, [ socket ])
  useEffect(() =>
  {
    getAllOrders()
  }, [ loading ]);

  const getAllOrders = async () =>
  {
    const res = await get_all_orders();
    setOrders(res.results.orders);
    setTotal(res.results.total)
    setLoading(false);
  }



  const update = async (id, orderProducts) =>
  {
    const data = {
      orderProducts
    }
    await update_order(id, data);
    setLoading(true);
  }
  return (
    <>
      <div className='grid grid-cols-1 md:flex md:flex-row justify-between items-center gap-2'>
        <Flex direction="column">
          <Title order={ 1 }>Pedidos Cocina</Title>
          <Title order={ 5 } fw="lighter">Total pedidos abiertos { total }</Title>
        </Flex>
      </div>
      <OrderIconReference orders={ orders } setOrderTypeVisible={ setOrderTypeVisible } orderTypeVisible={ orderTypeVisible } />
        <OrdersList orders={ orders } update={ update } orderTypeVisible={ orderTypeVisible } />
      
    </>
  )
}

export default Kitchen