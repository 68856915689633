import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Menu, Flex, Group, Burger, Text } from '@mantine/core'
import ToggleButton from './ToggleButton'
import Logo from '../../assets/logo.png'
import AuthContext from '../../contexts/Auth/authContext';
import { PiLockDuotone, PiSignOutDuotone, PiUserCircleDuotone, } from 'react-icons/pi';

const Header = ({ setShowChangePassword, toggleSidebar, show }) =>
{
  const navigate = useNavigate()
  const [ showNav, setShowNav ] = useState(false);
  const authContext = useContext(AuthContext)
  const { user, logout } = authContext;
  const toggleNav = () =>
  {
    setShowNav(!showNav)
  }

  const openChangePassword = () =>
  {
    setShowChangePassword(true);
    toggleNav()

  }


  const endSession = async () =>
  {
    await logout();
    navigate('/login')
  }

  return (
    <header className='flex flex-row items-center justify-between'>
      <Flex justify="flex-start" align="center" gap="sm">
        <Group mx="sm">
          <Burger opened={ show } onClick={ () => { toggleSidebar(); } } hiddenFrom="sm" size="sm" />
          <img src={ Logo } className='w-14 h-14' alt='Logo' />
        </Group>
      </Flex>
      <Flex className='w-4/12 lg:w-3/12 flex flew-row justify-end items-center'>
        <ToggleButton />
        <Menu shadow="md" radius="md" withArrow arrowSize="12" arrowPosition="side" >
          <Menu.Target>
            <Button
            
              px="md"
              color='indigo'
              leftSection={ <PiUserCircleDuotone className="text-2xl" /> }
              variant="subtle" size='lg'>
          <Text fz="sm" className='hidden md:block'>{ user ? user.name : 'Usuario' }</Text>
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item leftSection={ <PiLockDuotone className='text-lg' /> } onClick={ () => openChangePassword() }>
              Cambiar contraseña
            </Menu.Item>
            <Menu.Item leftSection={ <PiSignOutDuotone className='text-lg' /> } onClick={ () => endSession() }>
              Cerrar sesión
            </Menu.Item>


          </Menu.Dropdown>
        </Menu>
      </Flex>

    </header>
  )
}

export default Header