import { Title } from '@mantine/core'
import DailyRow from '../../Reports/Daily/DailyRow'

export const SalesByOrders = ({completeOrders, onConfirm}) => {
  return (
    <>
    <Title order={4} className="font-bold text-lg uppercase">Por Ordenes</Title>
    { completeOrders
      ? completeOrders.map((order) => (
        <DailyRow key={ order._id } order={ order }  onConfirm={onConfirm} report={false}/>
      ))
      : null }
  </>
  )
}
