/* eslint-disable import/no-anonymous-default-export */
import {
    DASHBOARD_UPDATE_DATE
} from '../types';



export default (state, action) => {
    //console.log(action)
    switch (action.type) {

        case DASHBOARD_UPDATE_DATE:
            return {
                dateStart: action.payload
            }

        default:
            console.log('default')
            return state;

    }
}