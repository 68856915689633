import React, { useState, useContext } from 'react'
import md5 from 'md5';

import { useFormik } from 'formik'
import { PasswordInput, Flex, Modal, Text, Button } from '@mantine/core';
import * as Yup from 'yup'
import { update_password } from '../../utils/apis/users'
import AuthContext from '../../contexts/Auth/authContext';

const ChangePassword = ({ id, show, setShow }) =>
{
  const authContext = useContext(AuthContext);
  const { user } = authContext
  const [ showPassword, setShowPassword ] = useState(false)

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {
      const password = {
        password: md5(data.password),
        repeatPassword: md5(data.repeatPassword)
      }
      await update_password(user.uid, password)
      formik.resetForm()
      setShow(!show)

    }
  })


  return (
    <Modal opened={ show } onClose={ setShow } title={ <Text fz="lg" fw="bold">Cambiar contraseña</Text> } centered>

      <PasswordInput
        label='Contraseña'
        variant='filled'
        size='lg'
        radius={ 'md' }
        value={ formik.values.password }
        onChange={ (e) => formik.setFieldValue('password', e.target.value) }
        />

      <PasswordInput
        label='Repetir contraseña'
        variant='filled'
        size='lg'
        radius={ 'md' }
        value={ formik.values.repeatPassword }
        onChange={ (e) => formik.setFieldValue('repeatPassword', e.target.value) } />

      <Flex justify="flex-end" align="center" gap="md" my="sm">

        <Button variant='light' size="md" radius="md" color='gray' mr={ 3 } onClick={ () => setShow(!show) }>
          Cancelar
        </Button>
        <Button variant='filled' size="md" radius="md" color='blue' onClick={ () => formik.handleSubmit() }>Cambiar</Button>

      </Flex>
    </Modal>
  )
}


export default ChangePassword;

function initialValues ()
{
  return {
    password: '',
    repeatPassword: '',

  }
}

function validationSchema ()
{
  return {
    password: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 10 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true),
    repeatPassword: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 10 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true).oneOf([ Yup.ref('password'), null ], 'No coinciden con la contraseña')
  }
}