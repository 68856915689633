import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Title, Button, Text } from "@mantine/core";
import { PiPlus } from "react-icons/pi";
import Loading from '../../../components/Loading/Loading';
import { get_categories } from '../../../utils/apis/categories';
import CategoriesTable from '../../../components/Categories/CategoriesTable';

const Categories = () =>
{
  const navigate = useNavigate()
  const [ items, setItems ] = useState();

  useEffect(() =>
  {
    (async () =>
    {
      const res = await get_categories();
      setItems(res.results)
    })()
  }, [])

  const editCategory = (id) =>
  {
    navigate(`/admin/categories/${ id }`);
  }



  return (
    <>
      <div className='grid grid-cols-2 md:flex md:flex-row justify-between items-start md:items-center gap-4'>
        <Title order={ 1 }>Categorias</Title>
        <Button color="blue"
          autoContrast
          variant="filled"
          size="md"
          radius="md"
          className="w-full sm:w-48"
          leftSection={ <PiPlus className="text-xl" /> }
          onClick={ () => navigate('/admin/categories/new') }><Text>Nuevo</Text></Button>
      </div>
      { !items && <Loading /> }
      { items && 
      <CategoriesTable items={ items } editCategory={ editCategory } />}
    </>
  )
}

export default Categories


