import React from 'react';
import { Table, Text } from '@mantine/core';

const EmptyOrder = () => {
  return (
    <Table.Tr>
      <Table.Td>
        <Text>No hay productos en la orden</Text>
      </Table.Td>
    </Table.Tr>
  )
}

export default EmptyOrder