import { NumericFormat } from 'react-number-format';
import { Table, Title } from '@mantine/core';
export const RowByProducts = ({ name, qty, price }) =>
{
  return (
    <Table.Tr>
      <Table.Td px="sm" py="sm" >
      { qty * -1 } - { name }
      </Table.Td>
      <Table.Td className='w-4/12'>
        <Title order={5} className="text-right">
          <NumericFormat
            value={ (qty * -1 * price) }
            displayType={ 'text' }
            thousandSeparator={ true }
            prefix={ '$ ' }
            decimalScale={ 2 }
            fixedDecimalScale={ true }
          />
        </Title>
      </Table.Td>
    </Table.Tr>
  )
}
