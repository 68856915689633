import React, { useState, useContext, useEffect } from 'react'
import { useFormik } from 'formik'
import { Modal, TextInput, Button, Text, Flex, NumberInput } from '@mantine/core'
import * as Yup from 'yup'
import { update_position_table } from '../../../utils/apis/tables'

const ChangePosition = ({ item, show, setShow, close }) =>
{
  useEffect(() =>
  {
    if (item) {
      formik.setFieldValue('x', item?.x)
      formik.setFieldValue('y', item?.y)
    }
  }, [ item ])  // eslint-disable-line

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {
      const positions = {
        x: data.x,
        y: data.y
      }
      await update_position_table(item._id, positions)
      formik.resetForm()
      close()

    }
  })


  return (
    <Modal opened={ show } onClose={ close } title={ <Text>Cambiar posición { item?.name }</Text> } centered>
      <NumberInput
        variant='filled'
        size="md"
        radius={ 'md' }
        my="xs"
        label='Posición Horizontal'
        value={ formik.values.x }
        onChange={ (e) => formik.setFieldValue('x', e) }
      />

      <NumberInput
        variant='filled'
        size="md"
        radius={ 'md' }
        my="xs"
        label='Posición Vertical'
        value={ formik.values.y }
        onChange={ (e) => formik.setFieldValue('y', e) } />

      <Flex justify="flex-end" align="center" gap="md" my="sm">
        <Button variant='light' size="md" radius="md" color='gray' mr={ 3 } onClick={ () => close() }>
          Cancelar
        </Button>
        <Button variant='filled' size="md" radius="md" color='blue' onClick={ () => formik.handleSubmit() }>Cambiar</Button>
      </Flex>
    </Modal>
  )
}


export default ChangePosition;

function initialValues ()
{
  return {
    x: '',
    y: '',

  }
}

function validationSchema ()
{
  return {
    x: Yup.string(),
    y: Yup.string(),
  }
}