import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'

import { Flex, Title, Button, Tooltip, Badge, ActionIcon, Card, Table, Image } from '@mantine/core'
import { PiPlus, PiTrashDuotone } from 'react-icons/pi'
import Loading from '../../../components/Loading/Loading';
import { get_sliders, suspend_slider, activate_slider, delete_slider } from '../../../utils/apis/sliders';
import UploadSlider from '../../../components/Modal/UploadSlider'

const Sliders = () =>
{
  const [ items, setItems ] = useState();
  const [ show, setShow ] = useState(false);
  const [ loading, setLoading ] = useState(true)

  useEffect(() =>
  {
    listImages()
  }, [ loading ])

  const listImages = async () =>
  {
    const res = await get_sliders();
    setItems(res.results)
    setLoading(false)
  }


  const onClose = async () =>
  {

    setShow(false)
    setLoading(!loading)
  }

  const suspendSlider = async (id) =>
  {
    setLoading(!loading)
    suspend_slider(id)

  }

  const activateSlider = async (id) =>
  {
    activate_slider(id)
    setLoading(!loading)
  }

  const removeSlider = async (id) =>
  {
    delete_slider(id)
    setLoading(!loading)
  }

  return (
    <>
      <div className='grid grid-cols-1 md:flex md:flex-row justify-between items-center gap-2'>
        <Flex direction="column">
          <Title order={ 1 }>Slider</Title>
          <Title order={ 5 } fw="lighter">La cantidad de imagenes activas son las que se mostraran</Title>
        </Flex>

        <Button
          className='w-full'
          color='blue'
          radius='md'
          size='md'
          leftSection={ <PiPlus className='text-xl' /> }
          onClick={ () => setShow(!loading) }>Nuevo</Button>

      </div>
      { !items && <Loading /> }
      { items && <Card withBorder shadow='md' my="md" radius="xl">
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th></Table.Th>
              <Table.Th>Nombre</Table.Th>
              <Table.Th>Ubicación</Table.Th>
              <Table.Th>Estado</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            { items ? items.map((item, i) =>
            {
              return (
                <Table.Tr key={ i }>
                  <Table.Td>
                    <Image
                      h={ 80 }
                      w="auto"
                      fit="contain" radius="md" src={ `${ process.env.REACT_APP_API_URL }/api/v1/uploads/${ item._id }` } alt={ `Slider ${ item._id }` } style={ { width: '100px', height: 'auto' } } />
                  </Table.Td>
                  <Table.Td>{ item.title }</Table.Td>
                  <Table.Td>{ item.section }</Table.Td>
                  <Table.Td>{ item?.status ?
                    <Badge autoContrast variant='filled' onClick={ () => suspendSlider(item._id) } color='green'>visible</Badge>
                    :
                    <Badge autoContrast variant='filled' onClick={ () => activateSlider(item._id) } color='red' >oculto</Badge> }
                  </Table.Td>
                  <Table.Td>
                    <Tooltip label='Eliminar imagen' aria-label='Eliminar imagen'>
                      <ActionIcon variant="light" onClick={ () => removeSlider(item._id) } color='red' >
                        <PiTrashDuotone />
                      </ActionIcon>
                    </Tooltip>
                  </Table.Td>
                </Table.Tr>
              )
            }) : null }
          </Table.Tbody>
        </Table>

      </Card> }
      <UploadSlider show={ show } setShow={ setShow } onClose={ onClose } />
    </>
  )
}

export default Sliders


function initialValues ()
{
  return {
    name: '',
  }
}

function validationSchema ()
{
  return {
    name: Yup.string().required(true),
  }
}