import React from "react";
import { Card, Table, Skeleton } from "@mantine/core";
import CategoriesRow from "./CategoriesRow";


const CategoriesTable = ({ items, editCategory }) => {
  return (
    <Card withBorder shadow='md' my="md" radius="xl">
      <Table highlightOnHover className='w-full overflow-x-auto'>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Nombre</Table.Th>
            <Table.Th>Estado</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {items
            ? items.map((item, i) => (
                <CategoriesRow key={i} item={item} editCategory={editCategory} />
              ))
            : [1,2,3,4,5,6,7,8,9,10].map(index =>(
                <Table.Tr key={index} className="w-full">
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                  <Table.Td className="w-full">
                    <Skeleton height={ 32 } radius="md" width="100%" w />
                  </Table.Td>
                </Table.Tr>
              )) }
        </Table.Tbody>
      </Table>
    </Card>
  );
};

export default CategoriesTable;
