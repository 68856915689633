import OrderView from './OrderView'
import BoxGrid from '../Commons/BoxGrid'
import { Grid } from '@mantine/core'

const OrdersList = ({ orders, cancel, orderTypeVisible }) =>
{

  const ordersFiltered = orderTypeVisible === 'all' ? orders : orders.filter(order => order.orderType === orderTypeVisible)

  return (
    <BoxGrid>
      { ordersFiltered ? ordersFiltered.map(order => (
        <Grid.Col span={ { base: 12, sm: 6 , md:4,lg:3} }>
          <OrderView key={ order._id } order={ order } cancel={ cancel } />
        </Grid.Col>

      )) : null }
    </BoxGrid>
  )
}

export default OrdersList