import React, { useState } from "react";
import { PiNoteDuotone, PiGiftDuotone, PiTrashDuotone, PiPlus, PiMinus, PiX } from "react-icons/pi";
import { Table, Text, ActionIcon, Badge, Box, Textarea, Flex } from "@mantine/core";


const OrderRow = ({
  product,
  increase,
  decrease,
  deleteProduct,
  addNote,
  user,
  authorization,
  markAsCourtesy,
  removeExtraFromProduct
}) =>
{
  const [ addNoteShow, setAddNoteShow ] = useState(false);
  const [ note, setNote ] = useState(product.note)
  return (
    <Table.Tr>
      <Table.Td>
        <Box>
          <Box className="flex flex-row items-center justify-between">
            <Text fw="normal" fz="md" className="truncate">{ product.name }</Text>
            <Flex justify="center" align="center" gap="md">
              { user.role.includes('MANAGER') && <ActionIcon
                size="lg"
                aria-label="Mark as Courtesy"
                color="green"
                variant="light"
                onClick={ () => markAsCourtesy(product.id) }
              >
                <PiGiftDuotone className="text-xl" />
              </ActionIcon> }
              <ActionIcon
                size="lg"
                aria-label="Add note"
                color="blue"
                variant="light"
                onClick={ () => setAddNoteShow(!addNoteShow) }
              >
                <PiNoteDuotone className="text-xl" />
              </ActionIcon>
            </Flex>
          </Box>
          <Box className="flex flex-row justify-between items-center gap-1 py-2">
            <Flex justify="space-between" align="center" gap="md">
              { product?.statusProductOrder && <Badge variant="filled" color="gray">Entregado</Badge> }
              { !product?.statusProductOrder && (
                <>
                  { product.quantity === 1 ? (
                    <ActionIcon
                      size="sm"
                      aria-label="Eliminar"
                      color="gray"
                      variant="outline"
                      onClick={ () =>
                      {
                        decrease(product.id);
                      } }
                      disabled
                    >
                      <PiMinus className="text-lg" />
                    </ActionIcon>
                  ) : (
                    <ActionIcon
                      size="sm"
                      aria-label="Restar"
                      onClick={ () =>
                      {
                        decrease(product.id);
                      } }
                    >
                      <PiMinus className="text-lg" />
                    </ActionIcon>
                  ) }
                  <Text mx="md" fw="bold"> { product.quantity }</Text>
                  <ActionIcon
                    size="sm"
                    aria-label="Agregar"
                    color="gray"
                    onClick={ () =>
                    {
                      increase(product.id);
                    } }
                  >
                    <PiPlus className="text-lg" />
                  </ActionIcon>
                </>
              ) }
            </Flex>

            <Flex direction="row" justify="center" align="center" gap="md" my="sm">
              { product.isCourtesy ?
                <Badge color="green">Cortesía</Badge>
                :

                <Text fw="bold">
                  $ { (product.price * product.quantity).toFixed(2) }
                </Text>
              }
              { product.quantity === 1 && (
                <ActionIcon
                  size="md"
                  aria-label="Eliminar producto"
                  color="red"
                  variant="light"
                  icon={ <PiTrashDuotone /> }
                  onClick={ () =>
                  {
                    deleteProduct(product.id);
                  } }
                >
                  <PiTrashDuotone />
                </ActionIcon>
              ) }
            </Flex>
          </Box>
          { addNoteShow ? (
            <Box>
              <Textarea label="Nota" radius={ 'md' } size="md" variant="filled" value={ note } onChange={ (e) => { addNote(product.id, e.target.value); setNote(e.target.value) } } />
            </Box>
          ) : null }

          { product.extras.length !== 0? (
            <Box>
              <Text>Extras:</Text>
              { product.extras.map((extra, index) => (
                <Flex justify='space-between' px="sm" my="xs">
                  <Text size="sm">{ extra.quantity } x { extra.name }</Text>
                  <Flex justify={ 'flex-end' } gap="sm">
                    <Text size="sm">$ { (extra.price * extra.quantity).toFixed(2) }</Text>
                    { !product?.statusProductOrder && <ActionIcon
                      size="sm"
                      aria-label="Eliminar"
                      color="red"
                      variant="outline"
                      onClick={ () => removeExtraFromProduct(product.id, extra._id) }
                    >
                      <PiX />
                    </ActionIcon> }
                  </Flex>
                </Flex>
              )) }
            </Box>
          ) : null }
        </Box>
      </Table.Td>
    </Table.Tr>
  );
};

export default OrderRow;
