import React from 'react'
import { Table, Badge, Text } from '@mantine/core'

const CategoriesRow = ({item, editCategory}) =>
{
  return (
    <Table.Tr>
      <Table.Td onClick={ () => editCategory(item._id) }>
        <Text >{ item?.name }</Text>
      </Table.Td>
      <Table.Td>
        { item?.status ? (
          <Badge variant="outline" color="blue">
            visible
          </Badge>
        ) : (
          <Badge variant="outline" color="red">
            oculto
          </Badge>
        ) }
      </Table.Td>
    </Table.Tr>
  )
}

export default CategoriesRow