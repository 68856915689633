import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
// core styles are required for all packages
import '@mantine/core/styles.css';
import '@mantine/dropzone/styles.css';
// other css files are required only if
// you are using components from the corresponding package
// import '@mantine/dates/styles.css';

import { SocketContext, socket } from './contexts/Socket/socket'
import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MantineProvider>
      <ModalsProvider>
        <SocketContext.Provider value={ socket }>
          <App />
        </SocketContext.Provider>
      </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
