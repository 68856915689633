import React, { useEffect, useContext } from 'react'

import { Card, Table, Divider, Text, Button, ScrollArea } from '@mantine/core'
import OrderRow from './OrderRow'
import { deleteProductCartApi } from '../../../utils/apis/orders'
import EmptyOrder from '../../Empties/EmptyOrder'
import AuthContext from '../../../contexts/Auth/authContext';
import AlertContext from '../../../contexts/Alert/alertContext';
import Loading from '../../Loading/Loading'
const Summary = ({
  order,
  reloadCart,
  orderProducts,
  setOrderProducts,
  loadingProducts,
  getTotal,
  setTotalPayment,
  authorization,
  increase,
  decrease,
  addNote,
  removeProduct,
  clearOrder,
  markAsCourtesy,
  removeExtraFromProduct
}) =>
{
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const alertContext = useContext(AlertContext);
  const { showAlert } = alertContext;
  useEffect(() =>
  {

    (async () =>
    {
      // const productTemp = await localOrder(order)
      setOrderProducts(orderProducts)
      setTotalPayment(getTotal(orderProducts))

    })()
  }, [ orderProducts ]);

  const deleteProductCart = async (id) =>
  {
    if ((user.role.includes('ADMIN'))) {
      const response = await deleteProductCartApi(id)
      if (response) {
        reloadCart(true)
      }
    }
    if ((authorization && !user.role.includes('MANAGER'))) {
      showAlert("Se requiere permisos", 'Contacta al gerente', 'error')
    }

    if (!authorization) {
      const response = await deleteProductCartApi(id)
      if (response) {
        reloadCart(true)
      }
    }

  }


  const clearCart = async () =>
  {
    clearOrder();
    reloadCart(true)
  }
  return (

    <Card withBorder shadow='md' my="sm" radius="xl">
      <div className='flex flex-row justify-between items-center'>
        <Text my="md" fw="bold" fz="lg">Resumen de la orden</Text>
        { orderProducts.length !== 0 && !authorization && <Button variant='light' color='red' size='md' radius='md' onClick={ clearCart }>Eliminar</Button> }
      </div>
      <Divider />
      <ScrollArea>
        <Table >
          <Table.Tbody>
            { orderProducts.length !== 0 && orderProducts.map(item => (
              <OrderRow addNote={ addNote } authorization={ authorization } user={ user } key={ item.id } product={ item } increase={ increase } decrease={ decrease } deleteProduct={ removeProduct } markAsCourtesy={ markAsCourtesy } removeExtraFromProduct={removeExtraFromProduct} />
            )) }
            { orderProducts.length === 0 && !loadingProducts &&
              <EmptyOrder />
            }
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </Card>


  )
}

export default Summary