import React from 'react'
import { Text } from '@mantine/core';
import IconError from '../../assets/404.png'
const NotFound = () => {

  return (
    <div className='flex flex-col w-full gap-1 overflow-x-auto h-full'>
      <div className='mx-2 mt-3'>
        <div className="flex flex-col items-center justify-center">
        <Text fz="xl"  className={`font-semibold font-sans`}>Pagina no encontrado</Text>
          <img className='w-3/12 h-auto' src={IconError} alt="Error" title='Error'/>
       
        </div>
      </div>
    </div>
  )
}

export default NotFound