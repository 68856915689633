import { Box, Text, TextInput, Title, Card } from '@mantine/core'

const SocialNetworks = ({ localSettings, handleInputChange }) =>
{
  return (
    <Card shadow='md' px="md" py="sm" my="md" withBorder radius="lg">
      <Box>
        <Title order={ 2 }>Redes sociales</Title>
      </Box>

      { localSettings.socialNetworks && localSettings.socialNetworks.map((item, index) => (
        <Box key={ index }>
          <Text>{ item.name }</Text>
          <TextInput
            placeholder={ item.name }
            value={ item.url }
            onChange={(e) => handleInputChange(index, 'url', e.target.value)}
          />
        </Box>
      )) }
    </Card>
  )
}

export default SocialNetworks