import { Divider, Text, Box } from '@mantine/core'
import React from 'react'
import TicketFooter from './TicketFooter'
import { TicketHeaderCloseOrder } from './TicketHeaderCloseOrder'
import TicketResumenRowClose from './TicketResumenRowClose'

const TicketCloseOrder = ({ id, order, subTotal, ivaAmount, orderDetail, orderInfo, total }) =>
{
  return (
    <div className='flex flex-col items-center'>
      <Box className='Ticket w-full hidden print:block ticketFont' w='100%' h={ '110%' }>
        <TicketHeaderCloseOrder orderDetail={ orderDetail } orderInfo={ orderInfo } id={ id } />
        <Divider my={ 2 }  className='text-gray-300 '/>
        <Box className='w-full'>
          {
            order ? order.map(item => (
              <TicketResumenRowClose key={ item._id } product={ item } />
            ))
              : null
          }
        </Box>
        <Divider my={ 2 }  className='text-gray-300'/>
        <Box className='w-full flex flex-row justify-between items-center gap-2 my-2'>
          <Box className='w-full'>
            <Box className='flex flex-row justify-between'>
              <Text fz="xs" className=' ticketFont w-full'>Subtotal</Text>
              <Text fz="xs" className=' ticketFont w-3/12 text-right'>$ { subTotal ? subTotal : '0.00' }</Text>
            </Box>
            <Box className='flex flex-row justify-between'>
              <Text fz="xs" className=' ticketFont w-full'>IVA</Text>
              <Text fz="xs" className=' ticketFont w-3/12 text-right'>$ { ivaAmount ? ivaAmount : '0.00' }</Text>
            </Box>
            <Box className='flex flex-row justify-between'>
              <Text fz="xs" className=' ticketFont w-full'>Total</Text>
              <Text fz="xs" className=' ticketFont w-3/12 text-right'>$ { total ? total : '0.00' }</Text>
            </Box>
          </Box>
        </Box>
        <br />
        <br />

        <TicketFooter />
      </Box>
      <Divider />
    </div>
  )
}

export default TicketCloseOrder