import {Box, Text } from '@mantine/core'

const Total = ({ total }) =>
{
  return (
    <Box w='100%'  className="grid grid-cols-2 place-items-end" >
      <Text fz="md" fw="bold" my="xs" className='place-self-start'>Total</Text>
      { total ? <Text fz="lg" fw="bold" my="xs" className=' uppercase'>$ { (total).toFixed(2) }</Text> : <Text fz="lg" fw="bold" my="xs" className=' uppercase'>$ 0.00</Text> }
    </Box>
  )
}

export default Total;
