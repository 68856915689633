/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { AppShell, ScrollArea, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import packageJson from '../../package.json';

import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import ChangePassword from '../components/Modal/ChangePassword';
import ModalExpired from '../components/Modal/ModalExpired';
import { Alerta } from '../components/Alerta/Alerta';

import { SocketContext } from '../contexts/Socket/socket';
import AlertContext from '../contexts/Alert/alertContext';
import AuthContext from '../contexts/Auth/authContext';
import useValidateExpiredDate from '../hooks/validateExpiredDate';

const PrivateRoute = ({ children }) => {
    const [opened, { toggle }] = useDisclosure();
    const [showModal, setShowModal] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showText, setShowText] = useState(true);

    const navigate = useNavigate();

    const { hoy, expired, expiredDate, loadingExpirated } = useValidateExpiredDate();
    const socket = useContext(SocketContext);
    const { alerta, showAlert } = useContext(AlertContext);
    const { token, user } = useContext(AuthContext);

    useEffect(() => {
        socket.on('disconnect', () => {
            showAlert('Desconectado', 'error');
        });
        return () => socket.off('disconnect'); // Cleanup on unmount
    }, [socket, showAlert]);

    useEffect(() => {
        if (!token) {
            navigate('/login');
            return;
        }

        if (hoy > expiredDate) {
            setShowModal(true);
        }
    }, [token, hoy, expiredDate, navigate]);


    if (!token) {
        return <Navigate to='/login' replace />;
    }

    return (
        <AppShell
            header={{ height: 68 }}
            navbar={{
                width: 180,
                breakpoint: 'sm',
                collapsed: { mobile: !opened },
            }}
            padding="md"
        >
            <AppShell.Header>
                <Header
                    show={opened}
                    toggleSidebar={toggle}
                    setShowChangePassword={setShowChangePassword}
                    setShowText={setShowText}
                    showText={showText}
                />
            </AppShell.Header>

            {user?.role.length > 1 && (
                <AppShell.Navbar p="md">
                    <AppShell.Section grow my="md" component={ScrollArea}>
                        <Sidebar
                            toggleSidebar={toggle}
                            showText={showText}
                        />
                    </AppShell.Section>
                    <AppShell.Section>
                        <Text fz="xs" className='text-center'>
                            V.{packageJson.version}
                        </Text>
                    </AppShell.Section>
                </AppShell.Navbar>
            )}

            <AppShell.Main className='relative'>{children}</AppShell.Main>

            <ChangePassword
                id={user._id}
                show={showChangePassword}
                setShow={setShowChangePassword}
            />

            <ModalExpired show={showModal} expired={expired} />

            {alerta?.msg === 'Desconectado' && <Alerta alerta={alerta} />}
        </AppShell>
    );
};

export default PrivateRoute;