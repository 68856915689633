import React from 'react'
import { Card, Text,Title, useMantineColorScheme } from '@mantine/core'
import { NumericFormat } from 'react-number-format';

const BoxSummary = ({ title, value, color='gray',  amount = true }) =>
{
  const { colorScheme } = useMantineColorScheme();
  return (

    <Card  h='88px' shadow="md" radius="md" px="md" bg={colorScheme === 'dark' ? 'dark':'white'} >
      <Text order={5} className='font-normal Capitlize text-xs md:text-lg mt-2 truncate'>{ title }</Text>
      <Title order={3} className='font-bold text-lg md:text-2xl text-right'>{
        amount ? <NumericFormat
          value={ value }
          displayType={ 'text' }
          thousandSeparator={ true }
          prefix={ '$' }
          decimalScale={ 2 }
          fixedDecimalScale={ true }
        /> : value }</Title>
    </Card>
  )
}

export default BoxSummary