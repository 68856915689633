import { useReducer } from 'react';
import dashboardReducer from './dashboardReducer';
import dashboardContext from './dashboardContext'
import dayjs from 'dayjs'
import { DASHBOARD_UPDATE_DATE } from '../types';

const DashboardState = ({ children }) => {
  const initialState = {
    dateStart: dayjs().format()
  };

  const [state, dispatch] = useReducer(dashboardReducer, initialState);

  const dashboardUpdateDate = (date) => {
    dispatch({
      type: DASHBOARD_UPDATE_DATE,
      payload: date
    });
  };
  
  return (
    <dashboardContext.Provider
      value={{
        dateStart: state.dateStart,
        dashboardUpdateDate
      }} 
    >
      {children}
    </dashboardContext.Provider>
  );
};

export default DashboardState;
