export const postLogin = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/auth/login`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json'
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const generateRecoveryToken = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/auth/generate-recovery`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json'
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const getRecoveryInfo = async (id) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/auth/recovery-info/${id}`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json'
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const recovery_update_password = async (id,data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/auth/recovery-change-password/${id}`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

