/* eslint-disable import/no-anonymous-default-export */
import
  {
    LIST_USERS,
    ADD_USER,
    DELETE_USER,
    UPDATE_USER
  } from '../types';



export default (state, action) =>
{
  //console.log(action)
  switch (action.type) {

    case LIST_USERS:
      return {
        ...state,
        total: action.payload.total,
        list: action.payload.users,
        loading: false,
        message: null
      }

    case ADD_USER:
    case DELETE_USER:
    case UPDATE_USER:
      return {
        ...state,
        loading: true
      }

    default:
      console.log('default')
      return state;

  }
}