import React from 'react'
import {Text, Box, Divider, Input, NumberInput, Button} from '@mantine/core'
const AmountSummary = ({ subTotal, ivaAmount, total, desc, setDesc, addTransaction, goBack, paymentType, totalMoneyChange, moneyChange, calculateChange,loading }) =>
{

  return (
    <>
      <Box>

        <Box className='flex flex-row items-center justify-end' p={ 2 }>
          <Text fz="sm" fw="bold" mx="sm">Subtotal</Text>
          <Text fz="lg">$ { subTotal } MXN</Text>
        </Box>

      </Box>

      <Box className='flex flex-row items-center justify-end' p={ 2 }>
        <Text fz="sm" fw="bold" mx="sm">Incluye IVA</Text>
        <Text fz="lg">$ { ivaAmount } MXN</Text>
      </Box>
      <Divider my="xs" variant="dashed" />
      <Box className='flex flex-row items-center justify-end' p={ 2 }>
        <Box className='w-full sm:w-6/12 md:w-3/12'>

            <NumberInput rightSection={<Text  fz="xs" pr="xs" fw="bold">%</Text>} label="Descuento" variant='filled' size="md" radius="md" type='number' min={ 0 } value={ desc } onChange={ (e) => setDesc(e) } />

        </Box>
      </Box>
      <Divider my="xs" variant="dashed" />

      <Box className='flex flex-row items-center justify-end' p={ 2 }>
        <Text fz="sm" fw="bold" mx="sm">Total</Text>
        <Text fz="lg">$ { total } MXN </Text>
      </Box>
      { paymentType === 'efectivo' && <>
        <Box className='flex flex-row items-center justify-end' p={ 2 }>
          <Box className='w-full sm:w-6/12 md:w-3/12'>

              <NumberInput label="Efectivo" rightSection={<Text fz="xs" pr="xs" fw="bold">MXN</Text>} variant='filled' size="md" radius="md" type='number' min={ 0 } value={ moneyChange } onChange={ (e) => calculateChange(e) } />

          </Box>
        </Box>
        <Divider my="md" variant="dashed" />

        <Box className='flex flex-row items-center justify-end' p={ 2 }>
          <Text fz="sm" fw="bold" mx="sm">Cambio: </Text>
          <Text className='text-md  mx-2'>${ totalMoneyChange.toFixed(2) } MXN</Text>

        </Box>
      </> }

      <Box className='flex flex-row items-center justify-end gap-2 mb-4' p="md" my="md">
        <Button  variant="light" size='md' radius="md" color='red' onClick={ goBack }>Cancelar</Button>
        { paymentType === 'efectivo' ? <Button   variant='filled' size='md' radius="md" color='blue' disabled={ (Number(total) + Number(totalMoneyChange) === Number(moneyChange) && Number(totalMoneyChange) >= 0) ? false : true } onClick={ addTransaction }>Pagar</Button> :
          <Button disabled={loading}  variant='filled' size='md' radius="md" color='blue' loading={loading} onClick={ addTransaction }>Pagar</Button> }
      </Box>

    </>
  )

}

export default AmountSummary