import React from 'react'
import { Card, Title, Table } from '@mantine/core'
import { RowByUser } from './RowByUser'

export const SalesByUser = ({ byUser }) =>
{
  return (
    <Card className="w-12/12" p="md" withBorder radius="md">
      <Title order={ 4 } className='uppercase'>Por usuario </Title>
      <Table>
        <Table.Tbody>
          {byUser && byUser.map((user, index) => (
            <RowByUser key={ index } { ...user } />
          )) }
        </Table.Tbody>
      </Table>
    </Card>
  )
}
