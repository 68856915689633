import React, { useState, useEffect } from 'react'
import { get_inventory, send_inventory_report } from '../../utils/apis/reports'
import { Title, Button, Text, Box, Divider, Input, Table, Card } from '@mantine/core';
import dayjs from 'dayjs';
const Inventory = () =>
{
  const [ items, setItems ] = useState();
  const [ dateStart, setDateStart ] = useState(null);
  const [ dateEnd, setDateEnd ] = useState(null);

  useEffect(() =>
  {
    (async () =>
    {
      setDateStart(dayjs().format('YYYY-MM-DD'))
      setDateEnd(dayjs().format('YYYY-MM-DD'))
      await getInventoryInfo(dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'))
    })()
  }, [])

  const getInventoryInfo = async (start, end) =>
  {
    const date1 = dayjs(start).set('hour', 6).set('minute', 0).set('second', 0).toISOString();
    const date2 = dayjs(end).set('hour', 6).set('minute', 0).set('second', 0).toISOString()
    const res = await get_inventory({ dateStart: date1, dateEnd: date2 });
    setItems(res.results.inventory)
  }

  const sendInventoryInfo = async (start, end) =>
  {
    const date1 = dayjs(start).set('hour', 6).set('minute', 0).set('second', 0).toISOString();
    const date2 = dayjs(end).set('hour', 6).set('minute', 0).set('second', 0).toISOString()
    await send_inventory_report({ dateStart: date1, dateEnd: date2 });
  }



  return (
    <>
      <div className='flex flex-col lg:flex-row justify-between items-stretch lg:items-center gap-2'>
        <Title order={ 1 }>Reporte de Inventario</Title>
        <Box className='flex flex-col lg:flex-row gap-2 print:hidden '>
          <Box className='flex flex-row gap-1 items-center'>
            <Text>De: </Text>
            <Input w={ '100%' } variant='filled' size='md' radius="md" type='date' value={ dateStart } onChange={ (e) => setDateStart(e.target.value) } />
          </Box>
          <Box className='flex flex-row gap-1 items-center'>
            <Text>a: </Text>
            <Input w={ '100%' } variant='filled' size='md' radius="md" type='date' value={ dateEnd } onChange={ (e) => setDateEnd(e.target.value) } />
          </Box>
          <Button size='md' radius="md" variant='filled' color='blue' onClick={ () => getInventoryInfo(dateStart, dateEnd) }>Buscar</Button>
          <Button size='md' radius="md" variant='light' color='green' onClick={ () => sendInventoryInfo(dateStart, dateEnd) }>Enviar Reporte</Button>
        </Box>
      </div>
      <Card withBorder shadow='md' my="md" radius="xl">
        <Table highlightOnHover className='w-full overflow-x-auto'>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Producto</Table.Th>
              <Table.Th>Cantidad</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {
              items ? items.map(product => (
                <Table.Tr key={ product._id }>
                  <Table.Td>{ product.name }</Table.Td>
                  <Table.Td>{ product.qty }</Table.Td>
                </Table.Tr>
              )) :
                null
            }
          </Table.Tbody>
        </Table>
      </Card>

    </>
  )
}

export default Inventory