import React from 'react'
import { Card, Badge, ActionIcon, Box } from '@mantine/core'
import { PiCornersOutDuotone } from 'react-icons/pi'
const MapView = ({ items, updatTable, availableTable, openChangePosition }) =>
{
  return (
    <div id="contenedor" className='flex flex-row gap-2 flex-wrap justify-around relative'>
      { items ? items.map((item, i) => (

        <Box key={ i } maxW='sm' overflow='hidden' p={ 4 } className="w-56 absolute border-gray-300" style={ { left: item.x, top: item.y } }>
          <Card shadow='md' radius="lg" withBorder p="md">
            <Box className='flex flex-row justify-end p-0'><ActionIcon variant='light' color="gray" size="sm" onClick={ () => openChangePosition(item) }>
              <PiCornersOutDuotone className='text-lg' />
            </ActionIcon>
            </Box>
            <Box ><span className='font-semibold'>{ item?.name }</span></Box>
            <Box className='flex flex-row gap-2'>
              <Box>{ item?.status ? <Badge autoContrast radius="md" variant='outline' onClick={ () => updatTable(item._id, false) } color='blue'>visible</Badge> : <Badge autoContrast radius="md" variant='outline' color='red' onClick={ () => updatTable(item._id, true) }>oculto</Badge> }</Box>

              { item?.status ?
                <span>{ item?.serviceStatus ? <Badge autoContrast radius="md" variant='filled' color='red' onClick={ () => availableTable(item._id, false) }>Ocupada</Badge> : <Badge autoContrast radius="md" variant='filled' onClick={ () => availableTable(item._id, true) } color='green'>Disponible</Badge> }</span>

                :
                <span><Badge autoContrast radius="md" variant='filled' color='gray'>No asignable</Badge></span>
              }
            </Box>
          </Card>
        </Box>

      )) : null }
    </div>
  )
}

export default MapView