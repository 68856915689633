import { Button, Text, Box, Card } from '@mantine/core'

const MenuView = ({ categories, changeCategory, selected }) =>
{
  return (
    <Box className='flex flex-row items-start overflow-scroll'>
      { categories && categories.map((category) => (

        <Button size="xl" className='text-left my-1 mx-1' key={ category._id } onClick={ () => { changeCategory(category._id) } } variant="filled" radius="md" color={ selected === category._id ? 'indigo' : 'gray' } >
          <Text className='w-full' lineClamp={ 4 }>   { category.name }</Text>
        </Button>

      ))
      }

    </Box>
  )
}

export default MenuView