import * as React from "react";
const uberIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 160.37 160.37"
    {...props}
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <rect
        width={160.37}
        height={160.37}
        rx={31.59}
        style={{
          fill: "#142328",
        }}
      />
      <path
        d="M76.86 107.45a8.33 8.33 0 1 0-8.33 8.23 8.22 8.22 0 0 0 8.33-8.23M83.66 94v27h-6.93v-2.43a13.66 13.66 0 0 1-8.73 3.04 14.17 14.17 0 1 1 0-28.33 13.72 13.72 0 0 1 8.77 3.12V94Zm23 20.84h-5.21c-1.59 0-2.61-.69-2.61-2.12v-12.64h7.82V94h-7.79v-7.7h-7V94h-5.26v6.11h5.27v14.35c0 3.62 2.61 6.49 7.31 6.49h7.5Zm15.79 6.8c8 0 12.52-3.74 12.52-8.92 0-3.68-2.67-6.43-8.26-7.61l-5.91-1.19c-3.43-.62-4.51-1.25-4.51-2.49 0-1.63 1.65-2.63 4.7-2.63 3.3 0 5.72.88 6.42 3.87h6.93c-.39-5.61-4.52-9.36-12.91-9.36-7.24 0-12.33 2.94-12.33 8.61 0 3.93 2.8 6.49 8.84 7.74l6.61 1.5c2.6.5 3.3 1.18 3.3 2.24 0 1.69-2 2.75-5.15 2.75-4 0-6.29-.87-7.18-3.87h-7c1 5.62 5.28 9.36 13.92 9.36M25.38 84.43h26.13v6.23H32.44v8.93H51v6H32.44v9h19.07v6.24H25.38Z"
        style={{
          fill: "#06c167",
        }}
      />
      <path
        d="M134.37 52.81v-4.9h-1.86A7.28 7.28 0 0 0 126 51.4v-3.28h-5.31v26.59h5.36V59.59c0-4.12 2.55-6.78 6.06-6.78ZM95.81 59a8.42 8.42 0 0 1 16.52 0Zm8.39-11.4a13.8 13.8 0 0 0-13.92 13.81c0 7.93 6.27 13.87 14.4 13.87a14.26 14.26 0 0 0 11.68-5.68l-3.88-2.82a9.36 9.36 0 0 1-7.8 3.92 9 9 0 0 1-9-7.56h22v-1.73c0-7.92-5.73-13.81-13.54-13.81M73.56 70.7a9.23 9.23 0 1 1 9.29-9.23 9.25 9.25 0 0 1-9.29 9.23M59 74.71h5.31v-3.34a13.66 13.66 0 0 0 9.67 4 13.9 13.9 0 1 0 0-27.79 13.55 13.55 0 0 0-9.62 4V38.21H59Zm-18.9-4.28c5.15 0 9.13-3.91 9.13-9.69V38.21h5.58v36.5h-5.53v-3.39a13.54 13.54 0 0 1-9.82 4c-8 0-14.08-5.69-14.08-14.29V38.21H31v22.53c0 5.89 3.93 9.69 9.14 9.69"
        style={{
          fill: "#f6f0ea",
        }}
      />
    </g>
  </svg>
);
export default uberIcon;
