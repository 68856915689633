import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Title, Text, Flex, Loader, Tooltip } from '@mantine/core';
import OrdersList from '../../components/Orders/OrdersList';
import { SocketContext } from '../../contexts/Socket/socket';
import { get_all_orders, cancel_order } from '../../utils/apis/orders'
import OrderIconReference from '../../components/Commons/OrderIconReference';
import AlertContext from '../../contexts/Alert/alertContext';
import { AlertaPermisos } from '../../components/Alerta/AlertaPermisos';
import { PiPlus } from 'react-icons/pi';

const Orders = () =>
{
  const socket = useContext(SocketContext)
  const alertContext = useContext(AlertContext);
  const { alerta } = alertContext
  const navigate = useNavigate();
  const [ total, setTotal ] = useState(0);
  const [ orders, setOrders ] = useState(null);
  const [ loading, setLoading ] = useState(true)
  const [ orderTypeVisible, setOrderTypeVisible ] = useState('all')


  useEffect(() =>
  {
    getAllOrders()
  }, []);
  useEffect(() =>
  {
    socket.on('listOrders', (payload) =>
    {

      setOrders(payload.orders);
      setTotal(payload.total);
    })
  }, [ socket ])
  useEffect(() =>
  {
    getAllOrders()
  }, [ loading ]);

  const getAllOrders = async () =>
  {
    const res = await get_all_orders();
    setOrders(res.results.orders);
    setTotal(res.results.total)
    setLoading(false);
  }

  const cancel = (id, orderProducts) =>
  {
    const data = {
      orderProducts
    }
    cancel_order(id, data);
    setLoading(true);
  }

  return (
    <div className='relative'>
      <div className='grid grid-cols-2 md:flex md:flex-row justify-between items-center gap-2 max-h-full'>
        <Flex direction="column" gap="sm">
          <Title order={ 1 }>Pedidos</Title>
          <Text fz="lg" fw="lighter" className='hidden md:block'>Total pedidos abiertos { total }</Text>
        </Flex>
        <Tooltip label="Nuevo pedido" aria-label='A tooltip' >
          <Button color='blue' size='md' radius="md" onClick={ () => navigate('/orders/new') }
            leftSection={ <PiPlus className="text-xl" /> }
          >
            <Text >Nuevo</Text>
          </Button>
        </Tooltip>
      </div>
      <OrderIconReference orders={ orders } setOrderTypeVisible={ setOrderTypeVisible } orderTypeVisible={ orderTypeVisible } />
      { loading ? <Flex my="md" justify="center" align="center">
        <Loader color="blue" size="xl" type="bars" />
      </Flex> :
        <OrdersList orders={ orders } orderTypeVisible={ orderTypeVisible } cancel={ cancel } /> }
      { alerta && <AlertaPermisos alerta={ alerta } /> }
    </div>
  )
}

export default Orders