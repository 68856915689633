import React from 'react'
import { Table, Text, Title } from '@mantine/core';
import { NumericFormat } from 'react-number-format';

export const RowByUser = ({ name, total }) =>
{
  return (
    <Table.Tr>
      <Table.Td px="sm" py="md">
        <Text className="text-sm md:text-lg">
          { name }
        </Text>

      </Table.Td>
      <Table.Td>
        <Title order={3} className="text-right">
          <NumericFormat
            value={ total }
            displayType={ 'text' }
            thousandSeparator={ true }
            prefix={ '$ ' }
            decimalScale={ 2 }
            fixedDecimalScale={ true }
          />
        </Title>
      </Table.Td>
    </Table.Tr>
  )
}
