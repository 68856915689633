import { useEffect, useState } from "react";
import { _getExpiredDate } from "../utils/apis/sso";
import dayjs from "dayjs";

const useValidateExpiredDate = () => {
  const [loadingExpired, setLoadingExpired] = useState(true);
  const [expired, setExpired] = useState("");
  const [expiredDate, setExpiredDate] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await _getExpiredDate();
        const { expired_date } = res.results;

        if (expired_date) {
          setExpired(dayjs(expired_date).format("DD/MM/YYYY"));
          setExpiredDate(Date.parse(dayjs(expired_date).format("YYYY-MM-DD")));
          setDataLoaded(true);
        }
        setLoadingExpired(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoadingExpired(false);
      }
    };

    fetchData();
  }, []);

  const hoy = Date.parse(dayjs().format("YYYY-MM-DD"));

  if (loadingExpired || !dataLoaded) {
    // Muestra un mensaje de carga mientras se obtiene la información
    return <div>Loading...</div>;
  }
  return { hoy, expired, expiredDate, loadingExpired };
};

export default useValidateExpiredDate;