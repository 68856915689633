import React from 'react'
import md5 from 'md5';
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Flex, Modal, Button, PasswordInput, TextInput, Checkbox, Text, ScrollArea } from '@mantine/core';

const ModalUser = ({ id, show, setShow, postNewUser }) =>
{

  const handleCheckboxChange = (event) =>
  {
    console.log(event)

    formik.setFieldValue('role', event);
  };
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object().shape(validationSchema()),
    onSubmit: async (data) =>
    {
      const user = {
        name: data.name,
        email: data.email.trim(),
        role: data.role,
        password: md5(data.password.trim()),
        repeatPassword: md5(data.repeatPassword.trim())
      }
      await postNewUser(user)
      formik.resetForm()
      setShow(!show)

    }
  })
  return (
    <Modal opened={ show } onClose={ setShow } title={ <Text>Alta de Usuario</Text> } centered>
      <ScrollArea h="556px" type="scroll">
        <TextInput
          variant='filled'
          radius='lg'
          size='lg'
          label="Nombre" placeholder='Nombre' value={ formik.values.name } onChange={ (e) => formik.setFieldValue('name', e.target.value) } isInvalid={ formik.errors.name }
          errorBorderColor='red.300' />

        <TextInput
          variant='filled'
          radius='lg'
          size='lg'
          label="Correo" placeholder='Email' value={ formik.values.email } onChange={ (e) => formik.setFieldValue('email', e.target.value) } isInvalid={ formik.errors.email }
          errorBorderColor='red.300' />
        <Text fz="lg" my="sm">Permisos</Text>
        <Flex direction="column" align="flex-start" gap="sm" my="sm">
          <Checkbox.Group value={ formik.values.role } onChange={ handleCheckboxChange }>
            <Checkbox
              label="ADMIN"
              value="ADMIN"
              checked={ formik.values.role.includes('ADMIN') }

            >
              Admin
            </Checkbox>
            <Checkbox
              label="WAITER"
              value="WAITER"
              checked={ formik.values.role.includes('WAITER') }

            >
              Mesero
            </Checkbox>
            <Checkbox
              label="CASHIER"
              value="CASHIER"
              checked={ formik.values.role.includes('CASHIER') }

            >
              Cajero
            </Checkbox>
            <Checkbox
              label="MANAGER"
              value="MANAGER"
              checked={ formik.values.role.includes('MANAGER') }

            >
              Manager
            </Checkbox>
            <Checkbox
              label="KITCHEN"
              value="KITCHEN"
              checked={ formik.values.role.includes('KITCHEN') }

            >
              Cocina
            </Checkbox>
            <Checkbox
              label="BAR"
              value="BAR"
              checked={ formik.values.role.includes('BAR') }

            >
              Barra
            </Checkbox>
          </Checkbox.Group>

        </Flex>

        <PasswordInput
          value={ formik.values.password }
          label='Contraseña'
          placeholder='Contraseña'
          variant='filled'
          radius='lg'
          size='lg'
          onChange={ (e) => formik.setFieldValue('password', e.target.value) }
          isInvalid={ formik.errors.password }
          errorBorderColor='red.300' />



        <PasswordInput value={ formik.values.repeatPassword }
          label='Repetir contraseña'
          placeholder='Repetir contaseña'
          variant='filled'
          radius='lg'
          size='lg'
          isInvalid={ formik.errors.repeatPassword }
          errorBorderColor='red.300'
          onChange={ (e) => formik.setFieldValue('repeatPassword', e.target.value) } />
      </ScrollArea>


      <Flex justify="flex-end" align="center" gap="md" my="sm">

        <Button variant='light' size="md" radius="md" color='gray' mr={ 3 } onClick={ () => setShow(!show) }>
          Cancelar
        </Button>
        <Button variant='filled' size="md" radius="md" color='blue' onClick={ () => formik.handleSubmit() }>Agregar</Button>

      </Flex>
    </Modal>
  )
}


export default ModalUser;

function initialValues ()
{
  return {
    name: '',
    email: '',
    role: [],
    password: '',
    repeatPassword: '',

  }
}

function validationSchema ()
{
  return {
    name: Yup.string().required(true, 'El nombre es obligatorio'),
    email: Yup.string().required(true, 'El correo es obligatorio'),
    role: Yup.array().min(1, 'El rol es obligatorio').required('El rol es obligatorio'),
    password: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 10 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true),
    repeatPassword: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 10 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true).oneOf([ Yup.ref('password'), null ], 'No coinciden con la contraseña')
  }
}