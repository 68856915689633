import React from 'react'
import { Card, Table, Badge, ActionIcon, Tooltip, Flex, ScrollArea } from '@mantine/core'
import { PiTrashDuotone, PiPencilDuotone, PiArrowsCounterClockwiseDuotone } from 'react-icons/pi'

const UserTable = ({ items, user, deleteUser, restoreUser, openUpdateUser }) =>
{


  return (
    <Card withBorder shadow='md' my="md" radius="xl">
      <ScrollArea>
        <Table highlightOnHover className='w-full overflow-x-auto'>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Nombre</Table.Th>
              <Table.Th>Correo</Table.Th>
              <Table.Th>Role</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th></Table.Th>

            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            { items ? items.map((item, i) => (
              item.role.includes('SUPER_ADMIN') ? null : (
                <Table.Tr key={ item.uid }>
                  <Table.Td>
                    { item.name }
                  </Table.Td>
                  <Table.Td>
                    { item._id }
                    { item.email }
                  </Table.Td>
                  <Table.Td>
                    { item.role.map(role => (<p>{ role }</p>)) }
                  </Table.Td>
                  <Table.Td>
                    <Badge color={ item.status ? 'green' : 'red' }>{ item.status ? 'Activo' : 'Suspendido' }</Badge>
                  </Table.Td>
                  <Table.Td>
                    <Flex justify="center" align="center" gap="sm">
                      { item.status && <Tooltip label='Dar de baja'>
                        <ActionIcon variant="light" color='red' disabled={ user.uid === item.uid ? true : false } onClick={ () => deleteUser(item.uid) } >
                          <PiTrashDuotone className='text-xl' />
                        </ActionIcon>
                      </Tooltip> }
                      { !item.status && <Tooltip label='Restaurar'>
                        <ActionIcon variant="light" color='blue' disabled={ user.uid === item.uid ? true : false } onClick={ () => restoreUser(item.uid) } >
                          <PiArrowsCounterClockwiseDuotone className='text-xl' />
                        </ActionIcon>
                      </Tooltip> }
                      <Tooltip label='Editar'>
                        <ActionIcon variant="light" color='blue' disabled={ user.uid === item.uid ? true : false } onClick={ () => openUpdateUser(item) } >
                          <PiPencilDuotone className='text-xl' />
                        </ActionIcon>
                      </Tooltip>
                    </Flex>
                  </Table.Td>
                </Table.Tr>
              )
            )) : null }
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </Card>
  )
}

export default UserTable