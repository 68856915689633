import { useState } from 'react';
import { Table, ActionIcon, Text, Flex, Checkbox, NumberInput } from '@mantine/core';
import { v4 as uuidv4 } from 'uuid';
import { PiPlus, PiSplitVerticalDuotone } from 'react-icons/pi';

const ItemMenuRow = ({ product, addProduct, reloadCart }) => {
  const [showExtras, setShowExtras] = useState(false);
  const [selectedExtras, setSelectedExtras] = useState({});

  const handleExtraChange = (extra, quantity) => {
    setSelectedExtras(prev => ({ ...prev, [extra._id]: { ...extra, quantity } }));
  };

  const handleAddProduct = (product, quantity) => {
    const extrasToAdd = Object.values(selectedExtras).filter(extra => extra.quantity > 0);
    addProduct(uuidv4(), product, quantity, extrasToAdd);
    reloadCart(true);
    setShowExtras(false);
    setSelectedExtras({})
  };

  return (
    <>
      <Table.Tr key={product._id}>
        <Table.Td className='w-7/12 flex flex-col md:flex-col md:items-start gap-1'>
          <Text fw="bold">{product.name}</Text>
          {product.inventoryStatus && <Text fw="bold" fz="xs" c={`${product.qty === 0 ? 'red' : product.qty >= 1 && product.qty <= 5 ? 'yellow' : product.qty >= 6 ? 'green' : 'gray'}`}>{product.qty <= 0 ? 'Agotado' : 'Disponible ' + product.qty}</Text>}
        </Table.Td>
        <Table.Td className='w-5/12 md:w-4/12'>
          <Flex className='flex flex-row gap-1 items-center justify-end flex-wrap md:flex-nowrap'>
            <Text fz="md" mx="md">$ {product.price.toFixed(2)}</Text>
            <ActionIcon size="lg" radius={'md'} aria-label='Agregar' onClick={() => handleAddProduct(product, 1)}>
              <PiPlus className='text-xl' />
            </ActionIcon>
            {product.extras && product.extras.length > 0 && <ActionIcon variant="outline" size="lg" radius={'md'} aria-label='Extras' onClick={() => setShowExtras(!showExtras)}>
              <PiSplitVerticalDuotone className='text-xl' />
            </ActionIcon>}
          </Flex>
        </Table.Td>
      </Table.Tr>
      {showExtras && product.extras && product.extras.length > 0 &&
        product.extras.map((extra, index) => (
          <Table.Tr key={index}>
            <Table.Td colSpan={2}>
              <Flex justify="space-between" align="center" className='flex flex-row' mx="md">
                <Checkbox label={`${extra.name} - $ ${extra.price.toFixed(2)}`} onChange={(e) => handleExtraChange(extra, e.target.checked ? 1 : 0)} />
                {selectedExtras[extra._id]?.quantity > 0 &&
                  <NumberInput defaultValue={1} min={1} onChange={(value) => handleExtraChange(extra, value)} className='w-3/12' />}
              </Flex>
            </Table.Td>
          </Table.Tr>
        ))
      }
    </>
  );
};

export default ItemMenuRow;
