const token =  localStorage.getItem('token')
  
export const get_users = async (data) => {
  
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}



export const get_user = async () => {
  
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/user`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}



export const post_user = async (data) => {
  
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}



export const update_user = async (data) => {
  
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/update/${data._id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const update_password = async (id,data) => {
  
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/${id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}



export const delete_user = async (id) => {

  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/${id}`;
    const params = {
      method: 'DELETE',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const restore_user = async (id) => {

  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/restore/${id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}