import React from 'react'
import { useLocation } from 'react-router-dom'

import { NavLink } from '@mantine/core'

const MenuItem = ({ title, icon, link, showText, onClick }) =>
{
  const location = useLocation()
  return (

      <NavLink
        onClick={onClick}
        active={ location.pathname === `${ link }` }
        autoContrast
        className="rounded-lg"
        px="sm"
        py="sm"
        variant="filled"
        href={ `#${ link }` }
        label={ showText ? title : null }
        leftSection={ icon }
      />
  )
}

export default MenuItem