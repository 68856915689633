import { PiStar, PiStarFill, PiEye, PiEyeClosed, PiPencilDuotone, PiDotsThreeOutlineVerticalDuotone, PiTrashDuotone } from "react-icons/pi";
import { Table, ActionIcon, Tooltip, Text, Menu, Box } from "@mantine/core";

const ProductRow = ({
  item,
  editProduct,
  updateProduct,
  updateRecommended,
  deleteProduct,
}) =>
{
  return (
    <Table.Tr key={ item._id } >
      <Table.Td className="w-full">

        <Box className="flex flex-row gap-1 items-center hover:text-blue-600">
          <Tooltip label="Editar">
            <div>
              <PiPencilDuotone
                className=" text-lg"
                onClick={ () => editProduct(item._id) }
              />{ " " }
            </div>
          </Tooltip>
          <Text fz="md" > { item?.name } </Text>
        </Box>


      </Table.Td>
      <Table.Td className="w-auto">
        <Text fw="bold">${ item?.price ? item?.price.toFixed(2) : 0.00}</Text>
      </Table.Td>
      <Table.Td className="w-auto">
        { item.inventoryStatus ? (
          <Text
            fw="bold"
            fz="md"
            c={ (item?.qty <= 5 && item?.qty >= 1) ? "yellow" : item?.qty <= 0 ? "red" : "green" }>
            { item?.qty }
          </Text>
        ) : null }
      </Table.Td>
      <Table.Td>
        <Box className="text-center">
          { item?.status ? (
            <Tooltip label="Visible">
              <ActionIcon
                variant="subtle"
                size="lg"
                color="blue"
                onClick={ () => updateProduct(item._id, false) }
              >
                <PiEye className="text-2xl" />
              </ActionIcon>
            </Tooltip>
          ) : (
            <Tooltip label="Oculto">
              <ActionIcon
                variant="subtle"
                size="lg"
                onClick={ () => updateProduct(item._id, true) }
                color="red"
              >
                <PiEyeClosed className="text-2xl" />
              </ActionIcon>
            </Tooltip>
          ) }
        </Box>
      </Table.Td>
      <Table.Td className="w-auto">
        <Text fw="normal">{ item?.category?.name }</Text>
      </Table.Td>
      <Table.Td className="w-auto">
        <Box className="text-center">
          { item?.recommended ? (
            <Tooltip label="Recomendado">
              <ActionIcon
                variant="subtle"
                size="lg"
                color="yellow"
                onClick={ () => updateRecommended(item._id) }
              >
                <PiStarFill className="text-2xl" />
              </ActionIcon>
            </Tooltip>
          ) : (
            <Tooltip label="No recomendado">
              <ActionIcon
                variant="subtle"
                size="lg"
                onClick={ () => updateRecommended(item._id) }
                color="gray"
              >
                <PiStar className="text-2xl" />
              </ActionIcon>
            </Tooltip>
          ) }
        </Box>
      </Table.Td>
      <Table.Td className="w-auto">
        <Menu trigger="click-hover" openDelay={ 100 } closeDelay={ 400 }>
          <Menu.Target>
            <ActionIcon variant="light" color="gray"> <PiDotsThreeOutlineVerticalDuotone /></ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Acciones</Menu.Label>
            <Menu.Item
              onClick={ () => editProduct(item._id) }
              leftSection={ <PiPencilDuotone /> }
            >Editar
            </Menu.Item>
            { item?.status ? (
              <Menu.Item
                leftSection={ <PiEyeClosed /> }
                onClick={ () => updateProduct(item._id, false) }
              > Ocultar
              </Menu.Item>
            ) : (
              <Menu.Item
                onClick={ () => updateProduct(item._id, true) }
                leftSection={ <PiEye /> }
              >
                Mostrar
              </Menu.Item>
            ) }
            <Menu.Divider />

            <Menu.Label>Recomendar</Menu.Label>
            { item?.recommended ? (
              <Menu.Item
                onClick={ () => updateRecommended(item._id) }
                leftSection={ <PiStarFill color="orange" /> }
              >
                Quitar
              </Menu.Item>
            ) : (
              <Menu.Item
                onClick={ () => updateRecommended(item._id) }
                leftSection={ <PiStar /> }
              >
              Agregar
              </Menu.Item>
            ) }
            <Menu.Divider />

            <Menu.Label>Peligro</Menu.Label>
            <Menu.Item
              leftSection={ <PiTrashDuotone color="red" /> }
              onClick={ () => deleteProduct(item._id) }
            > Eliminar
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Table.Td>
    </Table.Tr>
  );
};

export default ProductRow;
