import BoxGrid from '../Commons/BoxGrid'
import OrderView from '../Bar/OrderView'
import { Grid, Skeleton } from '@mantine/core'

const OrdersList = ({ orders, cancel, update, orderTypeVisible }) =>
{
  const ordersFiltered = orderTypeVisible === 'all' ? orders : orders.filter(order => order.orderType === orderTypeVisible)

  return (
    <BoxGrid>
      { ordersFiltered ? ordersFiltered.map(order => (
        <Grid.Col span={ { base: 12, sm: 6 , md:4,lg:3} }>
        <OrderView key={ order._id } order={ order } cancel={ cancel } update={ update } />
        </Grid.Col>
        
      )) : <>
        <Grid.Col span={ { base: 12, sm: 6, md: 4, lg: 3 } }>
          <Skeleton height={ 160 } radius="xl" />

        </Grid.Col>
        <Grid.Col span={ { base: 12, sm: 6, md: 4, lg: 3 } }>
          <Skeleton height={ 160 } radius="xl" />

        </Grid.Col>
        <Grid.Col span={ { base: 12, sm: 6, md: 4, lg: 3 } }>
          <Skeleton height={ 160 } radius="xl" />

        </Grid.Col>
        <Grid.Col span={ { base: 12, sm: 6, md: 4, lg: 3 } }>
          <Skeleton height={ 160 } radius="xl" />

        </Grid.Col>
        <Grid.Col span={ { base: 12, sm: 6, md: 4, lg: 3 } }>
          <Skeleton height={ 160 } radius="xl" />

        </Grid.Col>
        <Grid.Col span={ { base: 12, sm: 6, md: 4, lg: 3 } }>
          <Skeleton height={ 160 } radius="xl" />

        </Grid.Col>
        <Grid.Col span={ { base: 12, sm: 6, md: 4, lg: 3 } }>
          <Skeleton height={ 160 } radius="xl" />

        </Grid.Col>
        <Grid.Col span={ { base: 12, sm: 6, md: 4, lg: 3 } }>
          <Skeleton height={ 160 } radius="xl" />

        </Grid.Col>

      </> }
    </BoxGrid>
  )
}

export default OrdersList