import { createContext } from 'react';
import {io} from "socket.io-client";
const token =  localStorage.getItem('token')
  
export const socket = io.connect(`${process.env.REACT_APP_SOCKET}`, 
{path: process.env.REACT_APP_SOCKET_PATH,
    extraHeaders: {
      "Authorization": `Bearer ${token}`
      }
    });
export const SocketContext = createContext();