import { Alert, Box, Text } from "@mantine/core";
import { PiInfoDuotone } from "react-icons/pi";

export const Alerta = ({ alerta, title,hideAlert }) => {
  return (
    alerta ?
      <Box className="absolute top-1 right-1">
        <Alert
          onClose={hideAlert}
          autoContrast
          variant="filled"
          withCloseButton
          color="yellow"
          radius="lg"
          title={alerta.msg === 'Desconectado' ? 'Ocurrio un error' : 'Bienvenido'}
          icon={<PiInfoDuotone className="text-xl" />}
        >
          <Text mx="xs">
            {alerta.msg}
          </Text>
        </Alert>
      </Box>
      : null
  );
}
