
import * as React from "react";
const DidiIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 3000 3000"
    {...props}
  >
    <path
      fill="#646464"
      d="M1429.656 1242.01h159.66c160.678 0 288.391 98.821 288.391 265.459 0 181.611-109.76 274.435-288.391 274.435h-159.66V1242.01zm76.831 472.039h80.829c132.729 0 206.582-63.855 206.582-203.601 0-125.714-85.847-200.584-197.604-200.584h-90.825v404.185h1.018zm550.87-415.16h-81.848v-81.848h81.848v81.848zm-77.85 88.825h72.872v395.208h-72.872v-395.208zm208.581-145.704h159.659c160.679 0 288.43 98.821 288.43 265.459 0 181.611-109.76 274.435-288.429 274.435h-159.659l-.001-539.894zm77.85 472.039h80.829c132.73 0 206.581-63.855 206.581-203.601 0-125.714-85.845-200.584-197.642-200.584h-90.786v404.185h1.018zm549.851-415.16h-81.848v-81.848h81.848v81.848zm-76.831 88.825h72.833v395.208h-72.833v-395.208z"
    />
    <path
      fill="#FC9153"
      d="M711.129 1738.001c179.614-5.998 321.357-148.685 323.318-325.355v-152.645h197.605v168.675c-3.999 274.436-242.527 489.956-520.924 495.99-283.449-2.036-523.938-219.593-526.918-496.065v-302.347c0-30.929 19.952-50.919 49.862-50.919h800.374v184.667h-627.7c-15.992 0-25.95 9.995-25.95 25.95v123.753c1.999 178.632 146.724 326.335 330.333 328.333v-.037z"
    />
  </svg>
);
export default DidiIcon;
