import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";


import { Title, Button, TextInput, Flex, Box, Tabs } from "@mantine/core";
import { PiPlus } from "react-icons/pi";
import
{
  get_products,
  update_product,
  recommended_product,
  delete_product
} from "../../../utils/apis/products";
import ProductRow from "../../../components/Products/ProductRow";
import { ProductsTable } from "../../../components/Products/ProductsTable";
import { get_categories } from "../../../utils/apis/categories";

const Products = () =>
{
  const navigate = useNavigate();
  const [ searchText, setSearchText ] = useState("");
  const [ list, setList ] = useState(null);
  const [ cat, setCat ] = useState(null);
  const [ items, setItems ] = useState();
  const [activeTab, setActiveTab] = useState('all');

  useEffect(() =>
  {
    (async () =>
    {
      await getCategories();
      await getProducts();
    })();
  }, []);

  const getCategories = async () =>
  {
    const res = await get_categories();
    setCat(res.results);
    setList(res.results);

  }

  const getProducts = async () =>
  {
    const res = await get_products();
    setItems(res.results);
    setList(res.results);
  };
  const editProduct = (id) =>
  {
    navigate(`/admin/products/${ id }`);
  };

  const updateProduct = async (id, newStatus) =>
  {
    await update_product(id, { status: newStatus });
    getProducts();
  };

  const deleteProduct = async (id) =>
  {
    await delete_product(id);
    getProducts();
  };

  const updateRecommended = async (id) =>
  {
    await recommended_product(id);
    getProducts();
  };

  const searchBd = (texto) =>
  {
    setSearchText(texto);
    const originalList = list;
    const filters = searchText.split(" ");
    let resultFilter = originalList;
    for (let fl of filters) {
      resultFilter = resultFilter.filter((item) =>
      {
        const itemData = `${ item.name.toUpperCase() } ${ item.category.name.toUpperCase() }`;
        const textData = fl.toUpperCase();

        return itemData.indexOf(textData) > -1;
      });
    }
    setItems(resultFilter);
  };

  return (
    <>
      <div className="grid grid-cols-1 md:flex md:flex-row justify-between items-start md:items-center gap-1">
        <Title order={ 1 }>Productos</Title>
        <Flex direction={ { base: 'column', md: 'row' } } gap="sm">
          <TextInput
            placeholder="Filtrado rápido"
            variant="filled"
            size="md"
            radius="md"
            value={ searchText }
            onChange={ (e) => searchBd(e.target.value) }
          />
          <Button
            color="blue"
            autoContrast
            variant="filled"
            size="md"
            radius="md"
            className="w-full sm:w-48"
            leftSection={ <PiPlus className="text-xl" /> }
            onClick={ () => navigate("/admin/products/new") }
          >
            Nuevo
          </Button>
        </Flex>
      </div>
      <Tabs orientation={{base:"vertical", md:"horizontal"}} variant="pills" radius="lg" defaultValue="all" my="md" onChange={setActiveTab} value={activeTab}>
        <Tabs.List>
          <Tabs.Tab size="lg" value="all">
            Todos
          </Tabs.Tab>
          { cat && cat.map((item, index) => (
            <Tabs.Tab key={ index } value={ item._id }>
              { item.name }
            </Tabs.Tab>
          )) }

        </Tabs.List>
        <Tabs.Panel value="all">
          <Box className="w-full">
            <ProductsTable items={ items} editProduct={ editProduct } updateProduct={ updateProduct } updateRecommended={ updateRecommended } deleteProduct={ deleteProduct } />
          </Box>
        </Tabs.Panel>
       {
        cat && cat.map((cat, index) => (
          <Tabs.Panel value={cat._id}>
          <Box className="w-full">
            <ProductsTable items={items && items.filter(item => item.category._id === cat._id)} editProduct={ editProduct } updateProduct={ updateProduct } updateRecommended={ updateRecommended } deleteProduct={ deleteProduct } />
          </Box>
        </Tabs.Panel>
        ))
       }
      </Tabs>

    </>
  );
};

export default Products;
