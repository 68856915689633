import { Text, Table, Divider, Badge, Flex } from '@mantine/core'

const OrderResumenRow = ({ product }) =>
{
  return (
    <>

      <Table.Tr className="w-full">
        <Table.Td className='flex flex-row gap-1 items-baseline'>
          <Text fz="sm">{ product.quantity } x</Text>
          <Text>{ product.name }</Text>
        </Table.Td>
        <Table.Td className='text-right'>
          { product.isCourtesy && <Badge color="green">Cortesía</Badge> }
          <Text fz="sm" fw="bold">$ { (product.price * product.quantity).toFixed(2) }</Text>
        </Table.Td>

      </Table.Tr>
      { product.extras && product.extras.map((extra, index) => (
        <Table.Tr>
          <Table.Td>
            <Text fz="xs" fw="normal" px="xs">+ { extra.quantity } x { extra.name }</Text>
          </Table.Td>
          <Table.Td className='text-right'>
            <Text fz="sm" fw="bold">$ { (extra.quantity * extra.price).toFixed(2) }</Text>
          </Table.Td>
        </Table.Tr>
      )) }


    </>
  )
}

export default OrderResumenRow