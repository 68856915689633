import React, { useContext } from 'react';
import AuthContext from '../../contexts/Auth/authContext';
import {
  PiSquaresFourDuotone,
  PiStackDuotone,
  PiCookingPotDuotone,
  PiBeerBottleDuotone,
  PiIdentificationCardDuotone,
  PiSlideshowDuotone,
  PiCirclesFourDuotone,
  PiFileTextDuotone,
  PiProjectorScreenChartDuotone,
  PiReceiptDuotone,
  PiDiamondsFourDuotone,
  PiGearDuotone,
} from 'react-icons/pi';
import MenuItem from './MenuItem';
import { Divider } from '@mantine/core';

const Sidebar = ({ showText, toggleSidebar }) => {
  const { user } = useContext(AuthContext);

  const hasRole = (role) => user.role.includes(role);

  const handleToggleSidebar = () => {
    if (window.innerWidth < 768) {
      toggleSidebar();
    }
  };

  return (
    <nav>
      {hasRole('CASHIER') && (
        <MenuItem
          showText={showText}
          title="Dashboard"
          link="/admin"
          icon={<PiSquaresFourDuotone className="text-md lg:text-lg" />}
          onClick={handleToggleSidebar}
        />
      )}
      <MenuItem
        showText={showText}
        title="Pedidos"
        link="/orders"
        icon={<PiStackDuotone className="text-md lg:text-lg" />}
        onClick={handleToggleSidebar}
      />
      {hasRole('KITCHEN') && (
        <MenuItem
          showText={showText}
          title="Cocina"
          link="/orders/kitchen"
          icon={<PiCookingPotDuotone className="text-md lg:text-lg" />}
          onClick={handleToggleSidebar}
        />
      )}
      {hasRole('BAR') && (
        <MenuItem
          showText={showText}
          title="Barra"
          link="/orders/bar"
          icon={<PiBeerBottleDuotone className="text-md lg:text-lg" />}
          onClick={handleToggleSidebar}
        />
      )}
      {hasRole('MANAGER') && (
        <>
          <MenuItem
            showText={showText}
            title="Productos"
            link="/admin/products"
            icon={<PiFileTextDuotone className="text-md lg:text-lg" />}
            onClick={handleToggleSidebar}
          />
          <MenuItem
            showText={showText}
            title="Categorias"
            link="/admin/categories"
            icon={<PiCirclesFourDuotone className="text-md lg:text-lg" />}
            onClick={handleToggleSidebar}
          />
          <Divider className="my-2" />
          <MenuItem
            showText={showText}
            title="Ventas"
            link="/reports/sales"
            icon={<PiReceiptDuotone className="text-md lg:text-lg" />}
            onClick={handleToggleSidebar}
          />
          <MenuItem
            showText={showText}
            title="Inventario"
            link="/reports/inventory"
            icon={<PiProjectorScreenChartDuotone className="text-md lg:text-lg" />}
            onClick={handleToggleSidebar}
          />
        </>
      )}
      <MenuItem
        showText={showText}
        title="Mesas"
        link="/settings/tables"
        icon={<PiDiamondsFourDuotone className="text-md lg:text-lg" />}
        onClick={handleToggleSidebar}
      />
      {hasRole('ADMIN') && (
        <>
          <MenuItem
            showText={showText}
            title="Usuarios"
            link="/users"
            icon={<PiIdentificationCardDuotone className="text-md lg:text-lg" />}
            onClick={handleToggleSidebar}
          />
          {hasRole('MANAGER') && (
            <>
              <Divider className="my-2" />
              <MenuItem
                showText={showText}
                title="Sliders"
                link="/admin/sliders"
                icon={<PiSlideshowDuotone className="text-md lg:text-lg" />}
                onClick={handleToggleSidebar}
              />
            </>
          )}
          <Divider className="my-2" />
          <MenuItem
            showText={showText}
            title="Configuración"
            link="/settings"
            icon={<PiGearDuotone className="text-md lg:text-lg" />}
            onClick={handleToggleSidebar}
          />
        </>
      )}
    </nav>
  );
};

export default Sidebar;