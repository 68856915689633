import { Box, Text, ActionIcon, Badge, Flex } from '@mantine/core'
import { PiCheck } from 'react-icons/pi'
const ProductRow = ({ product, changeStatus }) =>
{
  return (
    <Box>
      <Flex justify="space-between" align="center">

        <Text fz="sm" fw="normal">{ product.quantity } x { product.name }</Text>
        {
          !product.statusProductOrder ? <ActionIcon radius="lg" variant="light" color='green' size='lg' onClick={ () => { changeStatus(product) } }>
            <PiCheck className='text-2xl' />
          </ActionIcon> : <Badge color='gray' className='truncate'>Entregado</Badge>
        }

      </Flex>

      <Box className='border-l-4 ml-2 rounded'>
        { product.extras && product.extras.map((extra, index) => (
          <Flex key={ index } justify="space-between" align="center" >
            <Text fz="xs" fw="normal" px="xs">+ { extra.quantity } x { extra.name }</Text>
          </Flex>
        )) }
      </Box>
      { product?.note && <Flex direction="column" my="xs">
        <Text fz="xs" fw="bold">Comentarios:</Text>
        <Text fz="xs" fw="normal" fs="italic" px="xs">  { product?.note }</Text>
      </Flex> }
    </Box>
  )
}

export default ProductRow