
import AppRoute from "./routes/AppRoute";
import AlertState from './contexts/Alert/alertState'
import AuthState from "./contexts/Auth/authState";
import UserState from "./contexts/Users/userState";
import DashboardState from "./contexts/Dashboard/dashboardState";
import SettingState from './contexts/Setting/settingState';

function App ()
{
  return (

    <AlertState>
      <AuthState>
        <SettingState>
          <DashboardState>
            <UserState>


              <AppRoute />

            </UserState>
          </DashboardState>
        </SettingState>
      </AuthState>
    </AlertState>


  );
}

export default App;
