import React from 'react'
import { Loader, Box, Text } from '@mantine/core'

const Loading = () => {
  return (
    <Box width='100%' p="4" display='flex' className='flex flex-col w-full items-center' >
        <Loader  size="xl" color="blue.4"/>
        <Text className='my-2'>Cargando...</Text>
    </Box>
  )
}

export default Loading